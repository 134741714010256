import { InfoCircle } from 'react-bootstrap-icons';
import { useAssignLocationStore, useGeneralSettingStore } from 'store';

import EditableParagraphGroup from 'components/editable-paragraph/EditableParagraph';
import Sector from 'components/sector/Sector';
import SelectGroup from 'components/select/Select';

const Location = () => {
	const [locations] = useGeneralSettingStore((state) => [state.locations]);
	const [
		assignedLocation,
		assignedLocationErrorMessage,
		updateAssignedLocation,
		updateAssignedLocationErrorMessage,
	] = useAssignLocationStore((state) => [
		state.assignedLocation,
		state.assignedLocationErrorMessage,
		state.updateAssignedLocation,
		state.updateAssignedLocationErrorMessage,
	]);

	return (
		<Sector title="Location">
			<SelectGroup
				label="Select Assigned Location"
				options={locations}
				value={assignedLocation}
				handleChange={updateAssignedLocation}
			/>
			<span>
				<InfoCircle className="mx-1" />
				To assign location you need to assign map to your Application.
			</span>
			<EditableParagraphGroup
				id="navigation-error-message"
				label="Navigation Error Message"
				placeholder="Enter your navigation error message ..."
				value={assignedLocationErrorMessage}
				handleChange={updateAssignedLocationErrorMessage}
			/>
		</Sector>
	);
};

export default Location;

let titleEl: HTMLElement | null = null;
let linkPlaceholderEl: HTMLElement | null = null;
let buttonEl: HTMLElement | null = null;

const updateModalContext = (
	title: string,
	linkPlaceholder: string,
	buttonText: string,
) => {
	instantiateModalEl();

	titleEl!.innerHTML = title;

	linkPlaceholderEl!.setAttribute('placeholder', linkPlaceholder);

	buttonEl!.innerHTML = buttonText;

	const progress = document.createElement('div');
	const progressBar = document.createElement('div');

	progress.classList.add('progress', 'd-none');
	progressBar.classList.add(
		'progress-bar',
		'progress-bar-striped',
		'progress-bar-animated',
	);

	progress.appendChild(progressBar);
	titleEl?.appendChild(progress);
};

const updateProgress = ({ percentage }: { percentage: number }) => {
	const progress = document.querySelector(
		'.gjs-mdl-dialog .gjs-mdl-header .gjs-mdl-title .progress',
	) as HTMLElement;

	if (!progress) return;

	const progressBar = progress.querySelector('.progress-bar') as HTMLElement;

	progress.classList.remove('d-none');

	progressBar.classList.add('bg-success');

	if (percentage === 0) {
		progressBar.style.width = '100%';
		progressBar.innerText = 'Processing ...';
		return;
	}

	if (percentage === 100) progress.classList.add('d-none');

	progressBar.style.width = `${percentage}%`;

	progressBar.innerText = `${percentage}%`;
};

const instantiateModalEl = () => {
	if (!titleEl)
		titleEl = document.querySelector(
			'.gjs-mdl-dialog .gjs-mdl-header .gjs-mdl-title',
		);
	if (!linkPlaceholderEl)
		linkPlaceholderEl = document.querySelector(
			'.gjs-mdl-dialog .gjs-mdl-content .gjs-am-assets-cont .gjs-am-assets-header .gjs-field.gjs-am-add-field > input',
		);
	if (!buttonEl)
		buttonEl = document.querySelector(
			'.gjs-mdl-dialog .gjs-mdl-content .gjs-am-assets-cont .gjs-am-assets-header .gjs-btn-prim',
		);
};

export { updateModalContext, updateProgress };

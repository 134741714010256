const registerCustomTooltips = () => {
	document.querySelectorAll('.custom-tooltip').forEach((element) => {
		element.addEventListener('mouseenter', handleMouseEnter);
		element.addEventListener('mouseleave', handleMouseLeave);
	});
};

const cleanupRegisteredCustomTooltips = () => {
	document.querySelectorAll('.custom-tooltip').forEach((element) => {
		element.removeEventListener('mouseenter', handleMouseEnter);
		element.removeEventListener('mouseleave', handleMouseLeave);
	});
};

export { cleanupRegisteredCustomTooltips, registerCustomTooltips };

const getTooltipEl = () => document.querySelector('#tooltip') as HTMLElement;

const handleMouseEnter = (mouseEvent: Event) => {
	const tooltipEl = getTooltipEl();
	const position = getTooltipPosition(
		(mouseEvent.target! as HTMLElement).getBoundingClientRect(),
	);

	setTooltipPosition(tooltipEl, position);
	setTooltipContent(tooltipEl, mouseEvent.target! as HTMLElement);
	setTooltipVisibility(tooltipEl, 'inline-block');
};

const handleMouseLeave = () => {
	const tooltipEl = getTooltipEl();
	setTooltipVisibility(tooltipEl, 'none');
};

const setTooltipContent = (tooltipEl: HTMLElement, targetEl: HTMLElement) => {
	tooltipEl.innerText = targetEl.dataset.content ?? 'No content Found';
};

const getTooltipPosition = (targetDOMRec: DOMRect) => {
	const right = window.innerWidth - targetDOMRec.right - 10;
	const top = targetDOMRec.top + targetDOMRec.height + 5;

	return { top, right };
};

const setTooltipPosition = (
	tooltipEl: HTMLElement,
	position: { top: number; right: number },
) => {
	tooltipEl.style.right = `${position.right}px`;
	tooltipEl.style.top = `${position.top}px`;
};

const setTooltipVisibility = (
	tooltipEl: HTMLElement,
	visibility: 'none' | 'inline-block',
) => {
	tooltipEl.style.display = visibility;
};

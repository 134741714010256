import type { AddComponentTypeOptions, Editor } from 'grapesjs';

const addButtonComponentPlugin = (editor: Editor) => {
	const componentsOptions: AddComponentTypeOptions = {
		isComponent: (el: HTMLElement) => el.tagName === 'Button',
		extend: 'text',
		model: {
			defaults: {
				tagName: 'span',
				resizable: true,
				editable: true,
				backgroundImage: true,
				attributes,
				traits: [],
				components: [{ type: 'textnode', content: 'Button' }],
			},
		},
		view: {
			init() {
				this.model.setStyle({ ...style, ...this.model.getStyle() });
			},
			onRender({ el }: { el: HTMLElement }) {
				el.innerHTML = el.innerText;
			},
		},
	};
	editor.DomComponents.addType('button', componentsOptions);
};

export { addButtonComponentPlugin };

const style = {
	height: '50px',
	width: '200px',
	color: '#FFFFFF',
	border: 'none',
	'border-color': '#ffffff',
	'border-top-left-radius': '10px',
	'border-top-right-radius': '10px',
	'border-bottom-left-radius': '10px',
	'border-bottom-right-radius': '10px',
	'background-color': '#7f6df2',
	display: 'flex',
	'align-items': 'center',
	'justify-content': 'center',
};

const attributes = {
	type: 'button',
	backgroundImage: false,
};

import { videoIcon } from 'editor/common/icons/videoIcon';

const videoBlock = {
	id: 'video',
	options: {
		category: 'Components',
		label: 'Video',
		media: videoIcon,
		content: {
			type: 'proven-video',
		},
	},
};

export { videoBlock };

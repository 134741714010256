import { Component } from 'editor/grapes-js/Component';
import { useObjectSettingsStore } from 'store';

const handleVideoImagePreviewChange = (previewImage: String) => {
	useObjectSettingsStore
		.getState()
		.updateAttributeProperty('image-preview', previewImage);

	Component.addAttribute('image-preview', previewImage);
	Component.addStyle(
		'background-image',
		`url(/assets/editor/video-player.svg), url(${previewImage})`,
	);
};

export { handleVideoImagePreviewChange };

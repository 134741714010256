import { Component } from 'editor/grapes-js/Component';
import { removeUnitFromValue } from 'modules/style-settings/utils/functions';
import { handleControlledInputChange } from 'modules/style-settings/utils/styles.service';
import { ARC_ICON_PATH, ROOT_ICON_PATH } from 'shared/vars/path';
import { useObjectSettingsStore } from 'store';

import ControlledInputGroup from 'components/input/ControlledInput';
import Sector from 'components/sector/Sector';

const Dimensions = () => {
	const [properties, object] = useObjectSettingsStore((state) => [
		state.componentStyles,
		state.object,
	]);

	Component.updateInstance(object!);

	return (
		<Sector title="Dimensions">
			<div className="row">
				<div className="col">
					<ControlledInputGroup
						id="width"
						label="Width"
						placeholder="auto"
						iconPath={`${ROOT_ICON_PATH}/px.svg`}
						iconPosition="end"
						name="width"
						value={removeUnitFromValue(properties.width)}
						onChange={handleControlledInputChange}
					/>
				</div>
				<div className="col">
					<ControlledInputGroup
						id="height"
						label="Height"
						placeholder="auto"
						iconPath={`${ROOT_ICON_PATH}/px.svg`}
						iconPosition="end"
						name="height"
						value={removeUnitFromValue(properties.height)}
						onChange={handleControlledInputChange}
					/>
				</div>
			</div>
			<div className="row my-2">
				<div className="col">
					<div className="horizontal-line" />
				</div>
			</div>
			<div className="row">
				<div className="col">
					<ControlledInputGroup
						id="padding-top"
						label="Padding top"
						placeholder="auto"
						iconPath={`${ROOT_ICON_PATH}/px.svg`}
						iconPosition="end"
						name="padding-top"
						value={removeUnitFromValue(properties['padding-top'])}
						onChange={handleControlledInputChange}
					/>
				</div>
				<div className="col">
					<ControlledInputGroup
						id="padding-right"
						label="Padding right"
						placeholder="auto"
						iconPath={`${ROOT_ICON_PATH}/px.svg`}
						iconPosition="end"
						name="padding-right"
						value={removeUnitFromValue(properties['padding-right'])}
						onChange={handleControlledInputChange}
					/>
				</div>
				<div className="w-100"></div>
				<div className="col">
					<ControlledInputGroup
						id="padding-bottom"
						label="Padding bottom"
						placeholder="auto"
						iconPath={`${ROOT_ICON_PATH}/px.svg`}
						iconPosition="end"
						name="padding-bottom"
						value={removeUnitFromValue(properties['padding-bottom'])}
						onChange={handleControlledInputChange}
					/>
				</div>
				<div className="col">
					<ControlledInputGroup
						id="padding-left"
						label="Padding left"
						placeholder="auto"
						iconPath={`${ROOT_ICON_PATH}/px.svg`}
						iconPosition="end"
						name="padding-left"
						value={removeUnitFromValue(properties['padding-left'])}
						onChange={handleControlledInputChange}
					/>
				</div>
			</div>
			<div>
				<label className="mb-1">Corner radius</label>
				<div className="row g-2">
					<div className="col">
						<ControlledInputGroup
							id="top-left-border-radius"
							placeholder="auto"
							iconPath={`${ARC_ICON_PATH}/top-left-arc.svg`}
							iconPosition="start"
							name="border-top-left-radius"
							value={removeUnitFromValue(properties['border-top-left-radius'])}
							onChange={handleControlledInputChange}
						/>
					</div>
					<div className="col">
						<ControlledInputGroup
							id="top-right-border-radius"
							placeholder="auto"
							iconPath={`${ARC_ICON_PATH}/top-right-arc.svg`}
							iconPosition="start"
							name="border-top-right-radius"
							value={removeUnitFromValue(properties['border-top-right-radius'])}
							onChange={handleControlledInputChange}
						/>
					</div>
					<div className="col">
						<ControlledInputGroup
							id="bottom-left-border-radius"
							placeholder="auto"
							iconPath={`${ARC_ICON_PATH}/bottom-left-arc.svg`}
							iconPosition="start"
							name="border-bottom-left-radius"
							value={removeUnitFromValue(
								properties['border-bottom-left-radius'],
							)}
							onChange={handleControlledInputChange}
						/>
					</div>
					<div className="col">
						<ControlledInputGroup
							id="bottom-right-border-radius"
							placeholder="auto"
							iconPath={`${ARC_ICON_PATH}/bottom-right-arc.svg`}
							iconPosition="start"
							name="border-bottom-right-radius"
							value={removeUnitFromValue(
								properties['border-bottom-right-radius'],
							)}
							onChange={handleControlledInputChange}
						/>
					</div>
				</div>
			</div>
		</Sector>
	);
};

export default Dimensions;

import { Component } from 'editor/grapes-js/Component';
import { handleControlledInputChange } from 'modules/attribute-settings/utils/attributes.service';
import { useObjectSettingsStore } from 'store';

import ControlledInputGroup from 'components/input/ControlledInput';
import Sector from 'components/sector/Sector';

const QRCodeTrait = () => {
	const [object, properties] = useObjectSettingsStore((state) => [
		state.object,
		state.componentAttributes,
	]);
	Component.updateInstance(object!);
	return (
		<Sector title="QR Code">
			<ControlledInputGroup
				id="qr-code-link"
				label="Link or String"
				placeholder="https://www.google.com"
				name="QRCode-URL"
				value={String(properties['QRCode-URL'] ?? '')}
				onChange={handleControlledInputChange}
			/>
		</Sector>
	);
};

export default QRCodeTrait;

import { type VoiceOverHandlerTypes } from 'store/use-voice-over/Action';
import { type VoiceOverStateTypes } from 'store/use-voice-over/State';
import { create } from 'zustand';

const initialState: VoiceOverStateTypes = {
	welcomeMessage: '',
	errorMessage: '',
	gesture: '',
};

const useVoiceOverStore = create<VoiceOverStateTypes & VoiceOverHandlerTypes>(
	(set) => ({
		...initialState,
		updateWelcomeMessage: (welcomeMessage) => set(() => ({ welcomeMessage })),
		updateErrorMessage: (errorMessage) => set(() => ({ errorMessage })),
		updateGesture: (gesture) => set(() => ({ gesture })),
	}),
);

export { useVoiceOverStore, type VoiceOverStateTypes };

import axios from 'axios';
import { VideoThumbnailGenerator } from 'browser-video-thumbnail-generator';
import { updateProgress } from 'editor/grapes-js/utils/custom-modal';
import { useConfigurationStore } from 'store';
import { Upload } from 'tus-js-client';
import {
	getFileExtension,
	getFileType,
	hasThumbnail,
	objectUrlSize,
	objectUrlToFile,
	validateFile,
} from 'utils/functions/files';
import { percentage } from 'utils/functions/numbers';
import { apiUrl, asset } from 'utils/functions/urls';
import { raiseError } from 'utils/message-senders/raiseError';

const uploadFiles = async (files: File[]): Promise<AssetFile[] | any> => {
	const responses: Array<PromiseSettledResult<AssetFile>> = await sendRequest(
		files,
	);

	const successUploads = responses.filter(
		(p) => p.status === 'fulfilled',
	) as Array<PromiseFulfilledResult<AssetFile>>;
	const failedUploads = responses.filter(
		(p) => p.status === 'rejected',
	) as PromiseRejectedResult[];

	if (failedUploads.length)
		raiseError([
			'Upload Failed For ',
			...failedUploads.map((upload) => ` ${upload.reason.file.name}`),
		]);

	updateProgress({ percentage: 100 });

	return successUploads.map((p) => p.value);
};

const sendRequest = async (
	files: File[],
): Promise<Array<PromiseSettledResult<AssetFile>>> => {
	const progress = new Proxy(
		{ totalBytes: 0, totalBytesUploaded: 0 },
		{
			set(progress: any, prop: string, newValue: any) {
				progress[prop] = newValue;

				updateProgress({
					percentage: percentage(
						progress.totalBytesUploaded,
						progress.totalBytes,
					),
				});

				return true;
			},
		},
	);

	return await Promise.allSettled(
		files
			.filter((file) => validateFile(file))
			.map(async (file): Promise<AssetFile> => {
				const filetype = getFileType(file);

				const fileExtension = getFileExtension(file);

				const { thumbnailObjectUrl, thumbnailSize } = await getThumbnail(
					file,
					filetype,
				);

				return await new Promise((resolve, reject) => {
					const upload: any = new Upload(file, {
						endpoint: apiUrl('resumable-files'),
						retryDelays: [0, 3000, 5000, 10000],
						chunkSize: 4 * 1024 * 1024, // 4MB
						headers: {
							Authorization: useConfigurationStore.getState().token,
						},
						metadata: {
							filename: file.name,
							filetype: `${filetype}/${fileExtension}`,
							contentType: `${filetype}/${fileExtension}`,
						},
						onChunkComplete: (chunkSize) => {
							(progress.totalBytesUploaded as number) += chunkSize;
						},

						onError: () => reject(upload),
						onSuccess: async () => {
							const uploadId = upload.url.split('/').at(-1);

							const uploadedFile: AssetFile = {
								name: upload.file.name,
								type: filetype,
							};

							if (filetype === 'image') {
								uploadedFile.src = asset(uploadId);
							}

							if (filetype === 'video') {
								uploadedFile.videoSrc = asset(uploadId);

								const thumbnail = await uploadThumbnail(
									thumbnailObjectUrl,
									uploadId,
								);

								uploadedFile.src = thumbnail
									? asset(thumbnail.data.data.id)
									: '';
							}

							(progress.totalBytesUploaded as number) += thumbnailSize;

							resolve(uploadedFile);
						},
					});

					(progress.totalBytes as number) += file.size + thumbnailSize;

					upload.start();
				});
			}),
	);
};

const getThumbnail = async (
	file: File,
	uploadType: string | undefined,
): Promise<{ thumbnailObjectUrl: string; thumbnailSize: number }> => {
	if (uploadType !== 'video' || !hasThumbnail(file))
		return { thumbnailObjectUrl: '', thumbnailSize: 0 };

	const fileObjectUrl = URL.createObjectURL(file);

	const generator = new VideoThumbnailGenerator(fileObjectUrl);

	const { thumbnail: thumbnailObjectUrl } = await generator.getThumbnail(
		'middle',
	);

	const thumbnailSize = await objectUrlSize(thumbnailObjectUrl);

	URL.revokeObjectURL(fileObjectUrl);

	return { thumbnailObjectUrl, thumbnailSize };
};

const uploadThumbnail = async (
	thumbnailObjectUrl: string,
	uploadId: string,
) => {
	if (!thumbnailObjectUrl) return null;

	const thumbnailFile = await objectUrlToFile(
		thumbnailObjectUrl,
		`${uploadId}_thumb`,
		'image/png',
	);

	const headers = { Authorization: useConfigurationStore.getState().token };

	const formData = new FormData();

	formData.append('file', thumbnailFile);

	return await axios.post(apiUrl('files'), formData, { headers });
};

export { uploadFiles };

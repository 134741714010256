import {
	combineUniqueKeywordsFromTwoList,
	type KeywordHandlerTypes,
	removeKeywordFromList,
} from 'store/use-keywords/Action';
import { type KeywordStateTypes } from 'store/use-keywords/State';
import { create } from 'zustand';

const initialState: KeywordStateTypes = {
	keywords: [] as string[],
};

const useKeywordStore = create<KeywordStateTypes & KeywordHandlerTypes>(
	(set) => ({
		...initialState,

		addKeyword: (keyword) =>
			set((state) => ({
				keywords: combineUniqueKeywordsFromTwoList(state.keywords, [keyword]),
			})),
		addKeywords: (keywords) =>
			set((state) => ({
				keywords: combineUniqueKeywordsFromTwoList(state.keywords, keywords),
			})),
		removeKeyword: (keyword) =>
			set((state) => ({
				keywords: removeKeywordFromList(state.keywords, keyword),
			})),
	}),
);

export { type KeywordStateTypes, useKeywordStore };

import { useEffect, useMemo } from 'react';
import { useAutocompleteStore } from 'store/use-autocomplete';
import { matchOptionTypes } from 'utils/functions/arrays';

import {
	handleChooseAutocompleteOption,
	handleClickOutsideAutocomplete,
} from './autocomplete.service';

import styles from './autocomplete.module.css';

const Autocomplete = () => {
	const [changedText, display, options, position] = useAutocompleteStore(
		(state) => [
			state.changedText,
			state.display,
			state.options,
			state.position,
		],
	);

	const filteredOptions = useMemo(
		() => matchOptionTypes(changedText, options),
		[changedText, options],
	);

	useEffect(() => {
		window.addEventListener('click', (clickEvent) =>
			handleClickOutsideAutocomplete(clickEvent, 'autocomplete-container'),
		);
		return () => {
			window.removeEventListener('click', (clickEvent) =>
				handleClickOutsideAutocomplete(clickEvent, 'autocomplete-container'),
			);
		};
	}, []);

	return (
		<ul
			id="autocomplete-container"
			className={`rounded ${styles.autocompleteListContainer}`}
			style={{ display, top: position.top, left: position.left }}
		>
			{filteredOptions.map((option) => (
				<li
					key={option.value}
					onClick={() => handleChooseAutocompleteOption(option.value)}
				>
					{option.label}
				</li>
			))}
			{!filteredOptions.length && (
				<li className={styles.disabledListItem}>No items found</li>
			)}
		</ul>
	);
};

export default Autocomplete;

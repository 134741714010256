import { Component } from 'editor/grapes-js/Component';
import {
	handleInputVariableBlur,
	handleInputVariableChange,
} from 'modules/attribute-settings/input/input.service';
import { handleControlledInputChange } from 'modules/attribute-settings/utils/attributes.service';
import { useObjectSettingsStore } from 'store';

import ControlledInputGroup from 'components/input/ControlledInput';
import Sector from 'components/sector/Sector';

const InputTrait = () => {
	const [object, properties] = useObjectSettingsStore((state) => [
		state.object,
		state.componentAttributes,
	]);
	Component.updateInstance(object!);
	return (
		<Sector title="Data Collection">
			<ControlledInputGroup
				id="input-name"
				label="Input Name"
				placeholder="username"
				name="input-name"
				value={String(properties['input-name'] ?? '')}
				onChange={handleControlledInputChange}
			/>

			<ControlledInputGroup
				id="variable"
				label="Variable"
				placeholder="username"
				name="variable"
				value={String(properties.variable ?? '')}
				onChange={handleInputVariableChange}
				onBlur={handleInputVariableBlur}
			/>

			<ControlledInputGroup
				id="placeholder"
				label="placeholder"
				placeholder="Input Field"
				name="placeholder"
				value={String(properties.placeholder ?? '')}
				onChange={handleControlledInputChange}
			/>
		</Sector>
	);
};

export default InputTrait;

/* eslint-disable */

import ReactDOM from 'react-dom/client';

import 'grapesjs/dist/css/grapes.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './index.css';

import App from 'App';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement,
);
root.render(<App />);

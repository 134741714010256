import { ARROW_ICON_PATH } from 'shared/vars/path';
import { useKeywordStore } from 'store';

import InputGroup from 'components/input/Input';
import Sector from 'components/sector/Sector';
import Tags from 'components/tags/Tags';

const ScreenInteraction = () => {
	const [keywords, addKeyword, removeKeyword] = useKeywordStore((state) => [
		state.keywords,
		state.addKeyword,
		state.removeKeyword,
	]);
	return (
		<Sector title="Incoming Screen Interactions">
			<InputGroup
				id="keywords-phrases"
				label="Keywords and phrases"
				placeholder="Enter your keyword or phrase"
				listenTo={[
					{ code: 'Enter', handleCallBack: addKeyword, resetValue: true },
				]}
				iconPath={`${ARROW_ICON_PATH}/down-left-arrow.svg`}
				iconPosition="end"
			/>
			<Tags tags={keywords} handleClick={removeKeyword} />
		</Sector>
	);
};

export default ScreenInteraction;

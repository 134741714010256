import { useEffect } from 'react';
import { handleUpdateInteraction } from 'modules/attribute-settings/outcoming-interaction/outcomeInteraction.service';
import { countryCodeOptions } from 'shared/options/countryCode';
import { useGeneralSettingStore } from 'store';

import EditableParagraphGroup from 'components/editable-paragraph/EditableParagraph';
import SelectGroup from 'components/select/Select';

import styles from './styles.module.css';

type Props = {
	interaction: SendSMSInteractionType;
	index: number;
};

const SendSMS = ({ interaction, index }: Props) => {
	const [applicationVariables, countryCode] = useGeneralSettingStore(
		(state) => [state.applicationVariables, state.countryCode],
	);
	useEffect(() => {
		if (!interaction['country-code'])
			handleUpdateInteraction(
				{
					...interaction,
					'country-code': countryCode ?? '',
				},
				index,
			);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<>
			<div>
				<label className="mb-2">Phone Number</label>
				<div className="d-flex align-items-baseline">
					<div className="flex-grow-1 reset-children-margin me-1">
						<SelectGroup
							label=""
							value={String(interaction['country-code'] ?? '')}
							options={countryCodeOptions}
							handleChange={(countryCode: string) => {
								handleUpdateInteraction(
									{
										...interaction,
										'country-code': countryCode,
									},
									index,
								);
							}}
						/>
					</div>
					<div className="flex-grow-1 reset-children-margin">
						<EditableParagraphGroup
							id="phone-number"
							label=""
							placeholder="+123456789"
							customStyling={styles.smsInputFields}
							value={String(interaction['phone-number'] ?? '')}
							autocompleteOptions={Object.values(applicationVariables)}
							handleChange={(content) => {
								handleUpdateInteraction(
									{
										...interaction,
										'phone-number': content,
									},
									index,
								);
							}}
						/>
					</div>
				</div>
			</div>
			<EditableParagraphGroup
				id="sms-content"
				label="SMS text"
				placeholder="Hi there, ..."
				customStyling={styles.smsInputFields}
				value={String(interaction['sms-content'] ?? '')}
				autocompleteOptions={Object.values(applicationVariables)}
				handleChange={(content) => {
					handleUpdateInteraction(
						{
							...interaction,
							'sms-content': content,
						},
						index,
					);
				}}
			/>
		</>
	);
};

export default SendSMS;

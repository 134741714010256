import { GrapesEditor } from 'editor/grapes-js/config/Grapes.config';
import {
	buttonBlock,
	imageBlock,
	inputBlock,
	QrCodeBlock,
	textBlock,
	videoBlock,
} from 'editor/grapes-js/registry/blocks';
import {
	addButtonComponentPlugin,
	addImageComponentPlugin,
	addInputComponentPlugin,
	addQRCodeComponentPlugin,
	addTextComponentPlugin,
	addVideoComponentPlugin,
} from 'editor/grapes-js/registry/components';
import {
	autoCompleteDeselectHandler,
	autocompleteUpdateHandler,
} from 'editor/grapes-js/registry/listeners/autocomplete';
import { clonedComponentsHandler } from 'editor/grapes-js/registry/listeners/clonedComponent';
import { nestedComponentsHandler } from 'editor/grapes-js/registry/listeners/componentNesting';
import {
	dragComponentsHandler,
	dragEndComponentsHandler,
	dragStopBlockHandler,
} from 'editor/grapes-js/registry/listeners/draggedComponent';
import {
	inputVariableDeselectHandler,
	inputVariableRemoveHandler,
} from 'editor/grapes-js/registry/listeners/inputVariables';
import { resizedComponentsHandler } from 'editor/grapes-js/registry/listeners/resizedComponent';
import { selectedComponentHandler } from 'editor/grapes-js/registry/listeners/selectedComponent';
import { pepperComponentSettings } from 'robots/components/pepper.config';
import { type IConfig } from 'robots/interfaces/IConfig';

class PepperConfig implements IConfig {
	private readonly _grapesEditor: GrapesEditor;

	private readonly _removedPanels = [
		'devices-c',
		'commands',
		'views',
		'views-container',
		'options',
	];

	private readonly _blocks = [
		textBlock,
		inputBlock,
		buttonBlock,
		imageBlock,
		QrCodeBlock,
		videoBlock,
	];

	private readonly _plugins = [
		addButtonComponentPlugin,
		addImageComponentPlugin,
		addInputComponentPlugin,
		addQRCodeComponentPlugin,
		addTextComponentPlugin,
		addVideoComponentPlugin,
	];

	constructor() {
		this._grapesEditor = new GrapesEditor(
			{
				container: '#grapes-js',
				blocks: '#blocks-container',
				layers: '#layers-container',
				styles: '#styles-container',
			},
			this._plugins,
		)
			.registerBlocks(this._blocks)
			.registerComponentUpdateListeners([
				autocompleteUpdateHandler,
				autoCompleteDeselectHandler,
				inputVariableDeselectHandler,
				inputVariableRemoveHandler,
				nestedComponentsHandler,
				resizedComponentsHandler,
				clonedComponentsHandler,
				dragComponentsHandler,
				dragEndComponentsHandler,
				dragStopBlockHandler,
				{
					listenerType: selectedComponentHandler.listenerType,
					handlerCallBack: selectedComponentHandler.handlerCallBack(
						pepperComponentSettings,
					),
				},
			])
			.removePanels(this._removedPanels)
			.removeDefaultRteTooltip()
			.onLoadEditor()
			.detectDirtyEditor()
			.renderEditor()
			.getEditor();
	}

	getConfig() {
		return this._grapesEditor;
	}
}

export { PepperConfig };

import { getRGBValueFromHex } from './colorPicker.service';

import styles from './styles.module.css';

type ColorPickerGroupType = {
	id: string;
	label: string;
	name: string;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
};

const ColorPicker = ({
	id,
	label,
	name,
	value,
	onChange,
	onBlur,
}: ColorPickerGroupType) => {
	const convertedValue = getRGBValueFromHex(value);

	return (
		<div id={id} className="mb-3">
			<label htmlFor={`${id}-color`} className="form-label">
				{label}
			</label>
			<span className="w-100 bg-white rounded d-flex align-items-center form-control no-glow border border-0 p-1">
				<input
					type="color"
					className={`form-control form-control-color no-glow p-0 border-0 ${styles.colorPicker}`}
					id={`${id}-color`}
					name={name}
					value={value.padEnd(7, value[value.length - 1])}
					onChange={onChange}
					onBlur={onBlur}
				/>
				<input
					type="text"
					autoComplete="off"
					placeholder="#FFFFFF"
					className={`form-control no-glow border border-0 inherit-fw py-0 px-1 ${styles.colorPickerInput}`}
					value={`rgb (${convertedValue.red},${convertedValue.green},${convertedValue.blue})`}
					readOnly
				/>
			</span>
		</div>
	);
};

export default ColorPicker;

import type { AddComponentTypeOptions, Editor } from 'grapesjs';

const addInputComponentPlugin = (editor: Editor) => {
	const componentsOptions: AddComponentTypeOptions = {
		isComponent: (el: HTMLElement) => el.tagName === 'INPUT',
		extend: 'input',
		model: {
			defaults: {
				tagName: 'input',
				resizable: true,
				attributes,
				traits: [],
			},
		},
		view: {
			init() {
				this.model.setStyle({ ...style, ...this.model.getStyle() });
			},
		},
	};
	editor.DomComponents.addType('input', componentsOptions);
};

export { addInputComponentPlugin };

const style = {
	height: '50px',
	width: '200px',
	'border-color': '#000000',
	'border-width': '1px',
	'border-style': 'solid',
	'border-top-left-radius': '10px',
	'border-top-right-radius': '10px',
	'border-bottom-left-radius': '10px',
	'border-bottom-right-radius': '10px',
};

const attributes = {
	type: 'input',
	placeholder: 'Input field',
	backgroundImage: false,
};

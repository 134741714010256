import { useGeneralSettingStore, useMaskDetectionStore } from 'store';

import CheckboxGroup from 'components/checkbox/Checkbox';
import EditableParagraphGroup from 'components/editable-paragraph/EditableParagraph';
import Sector from 'components/sector/Sector';

const MaskDetection = () => {
	const [applicationVariables] = useGeneralSettingStore((state) => [
		state.applicationVariables,
	]);
	const [
		hasMaskDetection,
		maskDetectionMessage,
		updateHasMaskDetection,
		updateMaskDetectionMessage,
	] = useMaskDetectionStore((state) => [
		state.hasMaskDetection,
		state.maskDetectionMessage,
		state.updateHasMaskDetection,
		state.updateMaskDetectionMessage,
	]);
	return (
		<Sector title="Mask Detection">
			<CheckboxGroup
				id="mask-detection"
				label="Mask detection needed"
				value={hasMaskDetection}
				handleChange={(e) => updateHasMaskDetection(e.target.checked)}
			/>
			<EditableParagraphGroup
				id="mask-detection-message"
				label="Mask Detection message"
				placeholder="Enter your message ..."
				value={maskDetectionMessage}
				handleChange={updateMaskDetectionMessage}
				autocompleteOptions={Object.values(applicationVariables)}
				disable={!hasMaskDetection}
			/>
		</Sector>
	);
};

export default MaskDetection;

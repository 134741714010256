import { AUTO_COMPLETED_COMPONENTS } from 'editor/global/variables';
import { useGeneralSettingStore } from 'store';

import {
	handleDisplayAutocomplete,
	unmountAutocompleteComponent,
} from 'components/autocomplete/autocomplete.service';

const autocompleteUpdateHandler = {
	listenerType: 'component:update' as const,
	handlerCallBack: (model: Component) => {
		const htmlEl: HTMLElement = model.getEl();
		const canvasEl = document.getElementById('grapes-js')!;
		if (isTextComponent(model, htmlEl))
			handleDisplayAutocomplete(
				htmlEl,
				Object.values(useGeneralSettingStore.getState().applicationVariables) ??
					[],
				(optionValue) => {
					model.components(`${model.getEl()?.innerHTML}${optionValue}`);
				},
				htmlEl.innerHTML,
				canvasEl,
			);
	},
};

const autoCompleteDeselectHandler = {
	listenerType: 'component:deselected' as const,
	handlerCallBack: (model: Component) => {
		if (!AUTO_COMPLETED_COMPONENTS.includes(model.attributes.type ?? ''))
			return;
		unmountAutocompleteComponent();
	},
};

export { autoCompleteDeselectHandler, autocompleteUpdateHandler };

const isTextComponent = (model: Component, htmlEl: HTMLElement | undefined) => {
	return model.ccid !== 'wrapper' && htmlEl?.dataset?.gjsType === 'text';
};

import { type SensoryInteractionHandlerTypes } from 'store/use-sensory-interaction/Action';
import { type SensoryInteractionStateTypes } from 'store/use-sensory-interaction/State';
import { create } from 'zustand';

const initialState: SensoryInteractionStateTypes = {
	headSensorAction: '',
	headSensorValue: '',
	handSensorAction: '',
	handSensorValue: '',
};

const useSensoryInteractionStore = create<
	SensoryInteractionStateTypes & SensoryInteractionHandlerTypes
>((set) => ({
	...initialState,
	updateHeadSensorAction: (headSensorAction) =>
		set((state) => ({
			headSensorAction,
			headSensorValue: headSensorAction === '' ? '' : state.headSensorValue,
		})),
	updateHeadSensorValue: (headSensorValue) => set(() => ({ headSensorValue })),
	updateHandSensorAction: (handSensorAction) =>
		set((state) => ({
			handSensorAction,
			handSensorValue: handSensorAction === '' ? '' : state.handSensorValue,
		})),
	updateHandSensorValue: (handSensorValue) => set(() => ({ handSensorValue })),
}));

export { type SensoryInteractionStateTypes, useSensoryInteractionStore };

import { updateModalContext } from 'editor/grapes-js/utils/custom-modal';
import type { AddComponentTypeOptions, Editor } from 'grapesjs';
import { handleVideoImagePreviewChange } from 'modules/attribute-settings/video/video.service';

const addVideoComponentPlugin = (editor: Editor) => {
	const componentsOptions: AddComponentTypeOptions = {
		isComponent: (el: HTMLElement) => el.tagName === 'DIV',
		model: {
			defaults: {
				tagName: 'div',
				resizable: true,
				editable: true,
				attributes,
				traits: [],
			},
		},
		view: {
			init({ model, editor }: { model: Component; editor: Editor }) {
				this.handleOpenModal = this.handleOpenModal.bind(this, editor);
				model.getEl().addEventListener('dblclick', this.handleOpenModal);

				model.setStyle({ ...style, ...this.model.getStyle() });
			},
			removed(removedEl: any) {
				removedEl.el.removeEventListener('dblclick', this.handleOpenModal);
			},
			handleOpenModal(editor: Editor) {
				handleUploadNewVideo(editor);
			},
		},
	};
	editor.DomComponents.addType('proven-video', componentsOptions);
};

export { addVideoComponentPlugin };

const style = {
	height: '250px',
	width: '250px',
	'border-color': '#ffffff',
	'background-repeat': 'no-repeat',
	'background-image': 'url(/assets/editor/video-player.svg)',
	'background-size': 'cover',
	'border-top-left-radius': '10px',
	'border-top-right-radius': '10px',
	'border-bottom-left-radius': '10px',
	'border-bottom-right-radius': '10px',
	'background-color': '#7f6df2',
	'background-position': 'center',
};

const attributes = {
	type: 'video',
	backgroundImage: false,
};

const handleUploadNewVideo = (editor: Editor) => {
	editor.AssetManager.open({
		types: ['video'],
		accept: 'video/*',
		async select(asset, complete) {
			const selected = editor.getSelected();
			if (selected?.is('proven-video')) {
				let videoSrc = asset.get('videoSrc');

				if (videoSrc) {
					handleVideoImagePreviewChange(asset.getSrc());
				} else {
					videoSrc = asset.getSrc();

					handleVideoImagePreviewChange('');
				}

				selected.addAttributes({ 'video-src': videoSrc });

				complete && editor.AssetManager.close();
			}
		},
	});
	updateModalContext('Videos', 'http://video-link.com', 'Add video');
};

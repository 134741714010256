import { handleDirtyEditor } from 'globals/events/dirtyEditor';
import { handleEscEditor } from 'globals/events/escEditor';
import { handleFullScreenChange } from 'globals/events/fullScreen';

document.addEventListener('fullscreenchange', handleFullScreenChange);
document.addEventListener('click', handleDirtyEditor);
window.addEventListener('keydown', handleEscEditor);

const cleanupEventListeners = () => {
	document.removeEventListener('fullscreenchange', handleFullScreenChange);
	document.removeEventListener('click', handleDirtyEditor);
	window.removeEventListener('keydown', handleEscEditor);
};

export { cleanupEventListeners };

import { useState } from 'react';
import { ArrowLeft } from 'react-bootstrap-icons';
import { ROOT_ICON_PATH } from 'shared/vars/path';
import { useGeneralSettingStore, useGlobalEventsStore } from 'store';

import {
	handleBackButton,
	handleFullScreenToggle,
	handleSaveScreen,
} from './navigation.service';

import styles from './navigation.module.css';

const Navigation = () => {
	const [zoomLevel, setZoomLevel] = useState<number | string>(100);
	const [isFullScreenOn] = useGlobalEventsStore((state) => [
		state.isFullScreenOn,
	]);
	const [screenName, updateScreenName] = useGeneralSettingStore((state) => [
		state.screenName,
		state.updateScreenName,
	]);

	const handleZoomLevelChange = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		const zoomValue = Math.min(+event.target.value, 100);

		setZoomLevel(zoomValue);
		window.editor.setZoomLevel(zoomValue);
	};

	return (
		<div
			className={`d-flex justify-content-between ${styles.topPanelContainer}`}
		>
			<section className="d-flex align-items-center">
				<button className={`btn ${styles.backBtn}`} onClick={handleBackButton}>
					<ArrowLeft size={22} />
				</button>
				<span className="input-group align-items-center mx-1">
					<label htmlFor="screen-name">Screen Name: </label>
					<input
						id="screen-name"
						type="text"
						className={`form-control mx-2 rounded no-glow ${styles.screenName}`}
						value={screenName}
						onChange={(e) => updateScreenName(e.currentTarget.value)}
					/>
				</span>
			</section>

			<section className="d-flex align-items-center">
				<div id="screen-commands"></div>
				<span
					onClick={handleFullScreenToggle}
					className={`px-2 py-1 rounded ${isFullScreenOn ? 'toggle-btn' : ''}`}
				>
					<img
						src={`${ROOT_ICON_PATH}/full-screen.svg`}
						alt="Full screen editor"
					/>
				</span>
				<div className="mx-2 d-flex justify-content-end align-items-center">
					<label htmlFor="zoom-level" className="mx-2">
						Zoom:
					</label>
					<input
						id="zoom-level"
						className={`form-control rounded border border-0 no-glow no-input-arrows ${styles.zoomLevelInput}`}
						value={zoomLevel}
						onChange={handleZoomLevelChange}
					/>
				</div>

				<div className="vertical-line" />
				<button
					className={`btn btn-primary mx-2 ${styles.saveBtn}`}
					onClick={handleSaveScreen}
				>
					Save Changes
				</button>
			</section>
		</div>
	);
};

export default Navigation;

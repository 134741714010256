import { handleUpdateInteraction } from 'modules/attribute-settings/outcoming-interaction/outcomeInteraction.service';
import { useGeneralSettingStore } from 'store';

import SelectGroup from 'components/select/Select';

type Props = {
	interaction: TelepresenceInteractionType;
	index: number;
};

const Telepresence = ({ interaction, index }: Props) => {
	const [contacts] = useGeneralSettingStore((state) => [state.contacts]);
	return (
		<SelectGroup
			label="Select contact"
			name="telepresence"
			value={interaction.contact}
			options={contacts}
			handleChange={(value: string) => {
				handleUpdateInteraction(
					{
						...interaction,
						contact: value,
					},
					index,
				);
			}}
		/>
	);
};

export default Telepresence;

import { Component } from 'editor/grapes-js/Component';
import { useObjectSettingsStore } from 'store';

const handleControlledInputChange = (
	e: React.ChangeEvent<HTMLInputElement>,
) => {
	const { name, value } = e.target;
	useObjectSettingsStore
		.getState()
		.updateAttributeProperty(name as ComponentAttributes, value);
	Component.addAttribute(name as ComponentAttributes, value);
};

const handleSelectChange = (value: string, { name }: { name: string }) => {
	useObjectSettingsStore
		.getState()
		.updateAttributeProperty(name as ComponentAttributes, value);
	Component.addAttribute(name as ComponentAttributes, value);
};

const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	const { name, checked } = e.target;
	useObjectSettingsStore
		.getState()
		.updateAttributeProperty(name as ComponentAttributes, checked);
	Component.addAttribute(name as ComponentAttributes, checked);
};

export {
	handleCheckboxChange,
	handleControlledInputChange,
	handleSelectChange,
};

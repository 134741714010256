import api from 'api';
import { mapOptionsList } from 'utils/functions/arrays';
import { isClickOutsideElement } from 'utils/functions/document';

const handleClickOutsideSelect = (
	selectElId: string,
	clickEvent: MouseEvent,
	showSelect: React.Dispatch<React.SetStateAction<boolean>>,
) => {
	if (isClickOutsideElement(selectElId, clickEvent)) showSelect(false);
};

const fetchAutocompleteSearch = async (
	apiUrl: string,
	params: object,
	optionsListParams?: object,
) => {
	try {
		const response = await api.get(apiUrl, { params });
		return mapOptionsList(response.data.data, optionsListParams);
	} catch (e) {
		return [];
	}
};

export { fetchAutocompleteSearch, handleClickOutsideSelect };

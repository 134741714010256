import { uploadImage } from 'components/image-stack/imageStack.service';

import styles from './styles.module.css';

interface Props {
	image: string;
	onAddImage: (src: string) => void;
	onRemoveImage: () => void;
}

const ImageStack = ({ image, onAddImage, onRemoveImage }: Props) => {
	return (
		<div className="w-100">
			{image && (
				<div className="position-relative my-1" onClick={onRemoveImage}>
					<div
						className={`${styles.imagePreview} rounded`}
						style={{ backgroundImage: image }}
					></div>
					<div className={`${styles.imageGuide} rounded`}>Click to remove</div>
				</div>
			)}
			<button
				type="button"
				className={`btn toggle-btn ${styles.uploadImage}`}
				onClick={() => uploadImage(onAddImage)}
			>
				{image ? 'Upload new background image' : '+ Add background image'}
			</button>
		</div>
	);
};

export default ImageStack;

import styles from './spinner.module.css';

const Spinner = () => {
	return (
		<div className={styles.spinner}>
			<div className="spinner-border" role="status"></div>
		</div>
	);
};

export default Spinner;

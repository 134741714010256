import { TEXT_ALIGN_ICON_PATH } from 'shared/vars/path';

const xAxisTextAlignment: Array<{
	iconPath: string;
	value: string;
}> = [
	{
		iconPath: `${TEXT_ALIGN_ICON_PATH}/text-align-left.svg`,
		value: 'flex-start',
	},
	{
		iconPath: `${TEXT_ALIGN_ICON_PATH}/text-align-center.svg`,
		value: 'center',
	},
	{
		iconPath: `${TEXT_ALIGN_ICON_PATH}/text-align-right.svg`,
		value: 'flex-end',
	},
];

const yAxisTextAlignment: Array<{
	iconPath: string;
	value: string;
}> = [
	{
		iconPath: `${TEXT_ALIGN_ICON_PATH}/text-align-top.svg`,
		value: 'flex-start',
	},
	{
		iconPath: `${TEXT_ALIGN_ICON_PATH}/text-align-vertical-top.svg`,
		value: 'center',
	},
	{
		iconPath: `${TEXT_ALIGN_ICON_PATH}/text-align-bottom.svg`,
		value: 'flex-end',
	},
];

export { xAxisTextAlignment, yAxisTextAlignment };

import { type GrapesEditor } from 'editor/grapes-js/config/Grapes.config';
import { defaultConfigurations } from 'editor/grapes-js/config/setup/defaults.config';
import type { EditorConfig } from 'grapesjs';
import { uploadFiles } from 'utils/files/uploadFiles';

const getEditorInitialObject = (
	containerSelector: string,
	blocksSelector: string,
	layersSelector: string,
): EditorConfig => {
	return {
		...defaultConfigurations,
		container: containerSelector,
		blockManager: { appendTo: blocksSelector, blocks: [] },
		layerManager: { appendTo: layersSelector },
		assetManager: {
			uploadFile: async (event: DragEvent) => {
				const files = Array.from((event.target as HTMLInputElement).files!);
				const uploadedFiles = await uploadFiles(files);

				if (!uploadedFiles) return;

				(window.editor as GrapesEditor).addAssets(uploadedFiles);
			},
		},
	};
};

export { getEditorInitialObject };

import { type ConfigurationsHandlerTypes } from 'store/use-configurations/Action';
import { type ConfigurationsStateTypes } from 'store/use-configurations/State';
import { create } from 'zustand';

const useConfigurationStore = create<
	ConfigurationsStateTypes & ConfigurationsHandlerTypes
>((set) => ({
	URL: '',
	token: '',

	updateConfigurations: (URL) => set(() => ({ URL })),
	updateToken: (token: string) =>
		set(() => ({
			token,
		})),
}));

export { type ConfigurationsStateTypes, useConfigurationStore };

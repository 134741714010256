import { useConfigurationStore, useGlobalEventsStore } from 'store';

const fireBackButton = () => {
	const { URL } = useConfigurationStore.getState();
	window.parent.postMessage(
		{
			type: 'back-button',
			isDirtyEditor: useGlobalEventsStore.getState().isDirtyEditor,
		},
		{ targetOrigin: URL },
	);
};

export { fireBackButton };

import { create } from 'zustand';

import { type autocompleteHandlerTypes } from './Action';
import { type autoCompleteStateTypes } from './State';

const initialState: autoCompleteStateTypes = {
	changedText: '',
	options: [],
	display: 'none',
	position: { top: 0, left: 0 },
	chosenOption: () => {},
};

const useAutocompleteStore = create<
	autoCompleteStateTypes & autocompleteHandlerTypes
>((set) => ({
	...initialState,
	updateText: (changedText) => {
		set(() => ({ changedText }));
	},
	updateOptions: (options) => {
		set(() => ({ options }));
	},

	updateDisplay: (display) => {
		set(() => ({ display }));
	},

	updatePosition: (position) => {
		set(() => ({ position }));
	},

	updateChosenOption: (chosenOption) => {
		set(() => ({ chosenOption }));
	},
}));

export { type autoCompleteStateTypes, useAutocompleteStore };

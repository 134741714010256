import { Component } from 'editor/grapes-js/Component';
import { removeUnitFromValue } from 'modules/style-settings/utils/functions';
import {
	handleColorPickerChange,
	handleControlledInputChange,
	handleSelectChange,
	handleTextAlignmentRadioChange,
} from 'modules/style-settings/utils/styles.service';
import { fontFamilies, fontWeights } from 'shared/options/fonts';
import {
	xAxisTextAlignment,
	yAxisTextAlignment,
} from 'shared/options/textAlignment';
import { ROOT_ICON_PATH } from 'shared/vars/path';
import { useObjectSettingsStore } from 'store';

import ColorPickerGroup from 'components/color-picker/ColorPicker';
import ControlledInputGroup from 'components/input/ControlledInput';
import RadioIconGroup from 'components/radio-icon/RadioIcon';
import Sector from 'components/sector/Sector';
import SelectGroup from 'components/select/Select';

const Typography = () => {
	const [properties, object] = useObjectSettingsStore((state) => [
		state.componentStyles,
		state.object,
	]);

	Component.updateInstance(object!);

	return (
		<Sector title="Typography">
			<div className="row">
				<div className="col">
					<SelectGroup
						label="Select Font"
						name="font-family"
						value={properties['font-family']}
						options={fontFamilies}
						handleChange={handleSelectChange}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-6">
					<ControlledInputGroup
						id="font-size"
						label="Select Font size"
						placeholder="16"
						iconPath={`${ROOT_ICON_PATH}/px.svg`}
						iconPosition="end"
						name="font-size"
						value={removeUnitFromValue(properties['font-size'])}
						onChange={handleControlledInputChange}
					/>
				</div>
				<div className="col-6">
					<SelectGroup
						label="Font weight"
						name="font-weight"
						value={properties['font-weight']}
						options={fontWeights}
						handleChange={handleSelectChange}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col">
					<ColorPickerGroup
						id="font-color"
						label="Font color"
						name="color"
						value={properties.color ?? '#000000'}
						onChange={handleColorPickerChange}
					/>
				</div>
			</div>
			<div className="row">
				<div className="col-6">
					<RadioIconGroup
						id="justify-content"
						label="Text align"
						radioName="justify-content"
						radios={xAxisTextAlignment}
						selectedRadio={properties['justify-content'] ?? 'flex-start'}
						onChange={handleTextAlignmentRadioChange}
					/>
				</div>
				<div className="col-6">
					<RadioIconGroup
						id="align-items"
						label=" "
						radioName="align-items"
						radios={yAxisTextAlignment}
						selectedRadio={properties['align-items'] ?? 'flex-start'}
						onChange={handleTextAlignmentRadioChange}
					/>
				</div>
			</div>
		</Sector>
	);
};

export default Typography;

import { gestureOptions } from 'shared/options/gestures';
import { sensorOptions } from 'shared/options/sensors';
import { useGeneralSettingStore, useSensoryInteractionStore } from 'store';

import Sector from 'components/sector/Sector';
import SelectGroup from 'components/select/Select';

const SensoryInteraction = () => {
	const [nextScreens] = useGeneralSettingStore((state) => [state.nextScreens]);

	const [
		headSensorAction,
		headSensorValue,
		handSensorAction,
		handSensorValue,
		updateHandSensorAction,
		updateHandSensorValue,
		updateHeadSensorAction,
		updateHeadSensorValue,
	] = useSensoryInteractionStore((state) => [
		state.headSensorAction,
		state.headSensorValue,
		state.handSensorAction,
		state.handSensorValue,
		state.updateHandSensorAction,
		state.updateHandSensorValue,
		state.updateHeadSensorAction,
		state.updateHeadSensorValue,
	]);

	const Sensor = (
		name: string,
		action: string,
		value: string,
		updateActionFn: (action: string) => void,
		updateValueFn: (action: string) => void,
	) => {
		return (
			<>
				<SelectGroup
					label={`${name} Sensor`}
					options={sensorOptions}
					value={action}
					handleChange={updateActionFn}
				/>

				{action === 'screen-transition' && (
					<SelectGroup
						label="Select Screen"
						options={nextScreens}
						value={value}
						handleChange={updateValueFn}
					/>
				)}
				{action === 'gesture' && (
					<SelectGroup
						label="Select Gesture"
						options={gestureOptions}
						value={value}
						handleChange={updateValueFn}
					/>
				)}
				{action === 'app' && (
					<SelectGroup
						label="Select Application"
						apiUrl="/custom/applications"
						apiParams={{ robotType: 'PEPPER' }}
						value={value}
						handleChange={updateValueFn}
					/>
				)}
			</>
		);
	};

	return (
		<Sector title="Sensory Interactions">
			{Sensor(
				'Head',
				headSensorAction,
				headSensorValue,
				updateHeadSensorAction,
				updateHeadSensorValue,
			)}
			{Sensor(
				'Hand',
				handSensorAction,
				handSensorValue,
				updateHandSensorAction,
				updateHandSensorValue,
			)}
		</Sector>
	);
};

export default SensoryInteraction;

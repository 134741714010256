import { useEffect } from 'react';

import {
	cleanupRegisteredCustomTooltips,
	registerCustomTooltips,
} from 'components/tooltip/tooltip.service';

import styles from './tooltip.module.css';

const Tooltip = () => {
	useEffect(() => {
		registerCustomTooltips();
		return () => {
			cleanupRegisteredCustomTooltips();
		};
	});
	return <span id="tooltip" className={`${styles.tooltipContent}`}></span>;
};

export default Tooltip;

import { Component } from 'editor/grapes-js/Component';
import { removeUnitFromValue } from 'modules/style-settings/utils/functions';
import {
	handleAddBackgroundImage,
	handleColorPickerChange,
	handleControlledInputChange,
	handleRemoveBackgroundImage,
	handleSelectChange,
} from 'modules/style-settings/utils/styles.service';
import { borderStyles } from 'shared/options/borders';
import { ROOT_ICON_PATH } from 'shared/vars/path';
import { useObjectSettingsStore } from 'store';

import ColorPickerGroup from 'components/color-picker/ColorPicker';
import ImageStack from 'components/image-stack/ImageStack';
import ControlledInputGroup from 'components/input/ControlledInput';
import Sector from 'components/sector/Sector';
import SelectGroup from 'components/select/Select';

const Decorations = () => {
	const [properties, object] = useObjectSettingsStore((state) => [
		state.componentStyles,
		state.object,
	]);

	Component.updateInstance(object!);

	return (
		<Sector title="Decorations">
			{object?.attributes.attributes?.backgroundImage && (
				<div className="row my-2">
					<div className="col">
						<ImageStack
							image={properties['background-image'] ?? ''}
							onAddImage={(src: string) => {
								handleAddBackgroundImage(src);
							}}
							onRemoveImage={handleRemoveBackgroundImage}
						/>
					</div>
				</div>
			)}
			<div className="row">
				<div className="col">
					<ColorPickerGroup
						id="background-color"
						label="Background color"
						name="background-color"
						value={properties['background-color'] ?? '#fff'}
						onChange={handleColorPickerChange}
					/>
				</div>
			</div>

			<div className="row my-2">
				<div className="col">
					<div className="horizontal-line" />
				</div>
			</div>

			<div className="row">
				<div className="col">
					<ControlledInputGroup
						id="border-width"
						label="Border width"
						placeholder="auto"
						iconPath={`${ROOT_ICON_PATH}/px.svg`}
						iconPosition="end"
						name="border-width"
						value={removeUnitFromValue(properties['border-width'])}
						onChange={handleControlledInputChange}
					/>
				</div>
				<div className="col">
					<SelectGroup
						label="Select Border style"
						name="border-style"
						value={properties['border-style']}
						options={borderStyles}
						handleChange={handleSelectChange}
					/>
				</div>
			</div>

			<div className="row">
				<div className="col">
					<ColorPickerGroup
						id="border-color"
						label="Border color"
						name="border-color"
						value={properties['border-color'] ?? '#000000'}
						onChange={handleColorPickerChange}
					/>
				</div>
			</div>
		</Sector>
	);
};

export default Decorations;

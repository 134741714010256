import { Component } from 'editor/grapes-js/Component';
import { useObjectSettingsStore } from 'store';

const handleInputVariableChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	const { name, value } = e.target;
	useObjectSettingsStore
		.getState()
		.updateAttributeProperty(name as ComponentAttributes, value);
};

const handleInputVariableBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
	const { name, value } = e.target;
	Component.addAttribute(name as ComponentAttributes, value);
};

export { handleInputVariableBlur, handleInputVariableChange };

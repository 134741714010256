import { useEffect } from 'react';
import { triggerTimerOptions } from 'shared/options/triggerTimer';
import {
	useGeneralSettingStore,
	useScreenTransitionStore,
	useVoiceOverStore,
} from 'store';

import CheckboxGroup from 'components/checkbox/Checkbox';
import Sector from 'components/sector/Sector';
import SelectGroup from 'components/select/Select';

const ScreenTransition = () => {
	const [nextScreens] = useGeneralSettingStore((state) => [state.nextScreens]);
	const [welcomeMessage, errorMessage] = useVoiceOverStore((state) => [
		state.welcomeMessage,
		state.errorMessage,
	]);
	const [
		hasScreenTransition,
		trigger,
		transitionTo,
		updateHasScreenTransition,
		updateTrigger,
		updateTransitionTo,
	] = useScreenTransitionStore((state) => [
		state.hasScreenTransition,
		state.trigger,
		state.transitionTo,
		state.updateHasScreenTransition,
		state.updateTrigger,
		state.updateTransitionTo,
	]);
	useEffect(() => {
		if (!welcomeMessage && !errorMessage && trigger.toString() === '0') {
			updateTrigger('');
		}
	}, [welcomeMessage, errorMessage, updateTrigger, trigger]);

	return (
		<Sector title="Automatic screen transition">
			<CheckboxGroup
				id="screen-transition-check"
				label="On"
				value={hasScreenTransition}
				handleChange={(e) => updateHasScreenTransition(e.target.checked)}
			/>
			<SelectGroup
				label="Select Set trigger"
				options={triggerTimerOptions}
				value={trigger}
				handleChange={updateTrigger}
				disable={!hasScreenTransition}
			/>
			<SelectGroup
				label="Select Transition to"
				options={nextScreens}
				value={transitionTo}
				handleChange={updateTransitionTo}
				disable={!hasScreenTransition}
			/>
		</Sector>
	);
};

export default ScreenTransition;

import { inputIcon } from 'editor/common/icons/inputIcon';

const inputBlock = {
	id: 'input',
	options: {
		category: 'Components',
		label: 'Input',
		media: inputIcon,
		content: {
			type: 'input',
		},
	},
};

export { inputBlock };

import { Component } from 'editor/grapes-js/Component';
import { appendUnitToValue } from 'modules/style-settings/utils/functions';
import { useObjectSettingsStore } from 'store';

const handleControlledInputChange = (
	e: React.ChangeEvent<HTMLInputElement>,
) => {
	const { name, value } = e.target;
	useObjectSettingsStore
		.getState()
		.updateStyleProperty(name as StyleProperties, appendUnitToValue(value));
	Component.addStyle(name as StyleProperties, appendUnitToValue(value));
};

const handleSelectChange = (value: string, { name }: { name: string }) => {
	useObjectSettingsStore
		.getState()
		.updateStyleProperty(name as StyleProperties, value);
	Component.addStyle(name as StyleProperties, value);
};

const handleTextAlignmentRadioChange = (
	e: React.ChangeEvent<HTMLInputElement>,
) => {
	const { name, value } = e.target;
	useObjectSettingsStore
		.getState()
		.updateStyleProperty(name as StyleProperties, value);
	Component.addStyle('display', 'flex');
	Component.addStyle(name as StyleProperties, value);

	const isHorizontalAlignment = name === 'justify-content';

	if (isHorizontalAlignment) {
		Component.addStyle(
			'text-align' as StyleProperties,
			value.split('-').at(-1),
		);
	}
};

const handleColorPickerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
	const { name, value } = e.target;
	useObjectSettingsStore
		.getState()
		.updateStyleProperty(name as StyleProperties, value);
	Component.addStyle(name as StyleProperties, value);
};

const handleAddBackgroundImage = (src: string) => {
	useObjectSettingsStore
		.getState()
		.updateStyleProperty('background-image', `url(${src})`);

	Component.addStyle('background-repeat', 'no-repeat');
	Component.addStyle('background-size', 'cover');
	Component.addStyle('background-position', 'center');
	Component.addStyle('background-image', `url(${src})`);
};

const handleRemoveBackgroundImage = () => {
	useObjectSettingsStore.getState().updateStyleProperty('background-image', '');
	Component.addStyle('background-image', '');
	Component.addStyle('background-repeat', '');
	Component.addStyle('background-size', '');
	Component.addStyle('background-position', '');
};

export {
	handleAddBackgroundImage,
	handleColorPickerChange,
	handleControlledInputChange,
	handleRemoveBackgroundImage,
	handleSelectChange,
	handleTextAlignmentRadioChange,
};

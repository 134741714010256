const getRGBValueFromHex = (hexValue: string) => {
	try {
		hexValue = hexValue.padEnd(7, hexValue[hexValue.length - 1]);
		const red = parseInt(hexValue.substring(1, 3), 16);
		const green = parseInt(hexValue.substring(3, 5), 16);
		const blue = parseInt(hexValue.substring(5), 16);
		return { red, green, blue };
	} catch (e) {
		return { red: '0', green: '0', blue: '0' };
	}
};

export { getRGBValueFromHex };

const uploadImage = (parentComponentCallback: (src: string) => void) => {
	window.editor.openModal({
		select: (asset, complete) => {
			if (complete) {
				parentComponentCallback(asset.attributes.src);
				window.editor.closeModal();
			}
		},
	});
};

export { uploadImage };

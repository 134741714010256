import { canvasScripts } from 'editor/grapes-js/config/setup/scripts.config';
import { canvasStyles } from 'editor/grapes-js/config/setup/styles.config';
import type { EditorConfig } from 'grapesjs';

const defaultConfigurations: Partial<EditorConfig> = {
	width: '100%',
	height: '100%',
	dragMode: 'absolute',
	fromElement: true,
	avoidDefaults: true,
	multipleSelection: true,
	autorender: false,
	colorPicker: {
		preferredFormat: 'hex',
		showAlpha: false,
		showPalette: false,
	},
	deviceManager: {
		devices: [
			{
				id: 'tablet',
				name: 'tablet',
				width: '1280px',
				height: '800px',
			},
		],
	},
	storageManager: {
		type: 'local',
		options: {
			local: {
				key: 'screen-editor',
				checkLocal: true,
			},
		},
		onStore(_: any, editor: any) {
			return {
				components: editor.getComponents(),
				styles: editor.getStyle(),
			};
		},
		onLoad: (result: any) => result.data,
	},
	canvas: {
		scripts: canvasScripts,
		styles: canvasStyles,
	},
	cssComposer: {
		rules: ['important'],
	},
};

export { defaultConfigurations };

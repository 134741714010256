import Language from 'modules/screen-settings/language/Language';
import Location from 'modules/screen-settings/location/Location';
import MaskDetection from 'modules/screen-settings/mask-detection/MaskDetection';
import ScreenInteraction from 'modules/screen-settings/screen-interaction/ScreenInteraction';
import ScreenTransition from 'modules/screen-settings/screen-transition/ScreenTransition';
import SensoryInteraction from 'modules/screen-settings/sensory-interaction/SensoryInteraction';
import VoiceOver from 'modules/screen-settings/voice-over/VoiceOver';
import { SUPPORTED_ROBOTS } from 'shared/vars/robots';

const getScreenSettingsComponents = (robotType: SUPPORTED_ROBOTS) => {
	switch (robotType) {
		case SUPPORTED_ROBOTS.PEPPER:
			return pepperScreenSettings;
		case SUPPORTED_ROBOTS.TEMI:
			return temiScreenSettings;
		default:
			return pepperScreenSettings;
	}
};

export { getScreenSettingsComponents };

const pepperScreenSettings = [
	Language,
	ScreenInteraction,
	SensoryInteraction,
	VoiceOver,
	MaskDetection,
	ScreenTransition,
];

const temiScreenSettings = [
	Language,
	ScreenInteraction,
	VoiceOver,
	Location,
	ScreenTransition,
];

import InputTrait from 'modules/attribute-settings/input/Input';
import OutcomingInteractionsTrait from 'modules/attribute-settings/outcoming-interaction/OutcomeInteraction';
import QRCodeTrait from 'modules/attribute-settings/qr-code/QRCode';
import VideoTrait from 'modules/attribute-settings/video/Video';
import Decorations from 'modules/style-settings/decorations/Decorations';
import Dimensions from 'modules/style-settings/dimensions/Dimensions';
import Typography from 'modules/style-settings/typography/Typography';

const temiComponentSettings: ComponentSettings = {
	wrapper: {
		styles: [Decorations],
		properties: [],
	},
	text: {
		styles: [Dimensions, Typography, Decorations],
		properties: [OutcomingInteractionsTrait],
	},
	input: {
		styles: [Dimensions, Typography, Decorations],
		properties: [InputTrait],
	},
	button: {
		styles: [Dimensions, Typography, Decorations],
		properties: [OutcomingInteractionsTrait],
	},
	image: {
		styles: [Dimensions, Decorations],
		properties: [OutcomingInteractionsTrait],
	},
	'QR-code': {
		styles: [Dimensions, Decorations],
		properties: [QRCodeTrait],
	},
	'proven-video': {
		styles: [Dimensions, Decorations],
		properties: [VideoTrait],
	},
};

export { temiComponentSettings };

import { Component } from 'editor/grapes-js/Component';
import { interactionTypes } from 'shared/options/outcomingInteractions';
import { useObjectSettingsStore } from 'store';
import { getUniqueArrayItems, removeByValue } from 'utils/functions/arrays';
import { randomId } from 'utils/functions/strings';

const handleAddVoiceInput = (
	voiceInput: string,
	interaction: ScreenTransitionInteractionType,
	index: number,
) => {
	const voiceInputCurrentState = interaction['voice-input'] ?? [];
	const uniqueVoiceInputs = getUniqueArrayItems(
		voiceInputCurrentState,
		voiceInput,
	) as string[];

	interaction['voice-input'] = uniqueVoiceInputs;

	handleUpdateInteraction(interaction, index);
};

const handleRemoveVoiceInput = (
	removedVoiceInput: string,
	interaction: ScreenTransitionInteractionType,
	index: number,
) => {
	const voiceInputCurrentState = interaction['voice-input'] ?? [];

	const voiceInputs = removeByValue(
		voiceInputCurrentState,
		removedVoiceInput,
	) as string[];

	interaction['voice-input'] = voiceInputs;

	handleUpdateInteraction(interaction, index);
};

const getTags = (tags?: string | string[]) => {
	if (!tags) return [];
	if (Array.isArray(tags)) return tags;
	return tags.trim().split(',');
};

const getCurrentInteractionsState = () => {
	const interactionsCurrentState =
		useObjectSettingsStore.getState().componentAttributes.interactions ?? [];

	return interactionsCurrentState as InteractionType[];
};

const handleAddInteraction = () => {
	const interactionsCurrentState = getCurrentInteractionsState();

	const nextInteraction = interactionTypes.filter(
		(interactionType) =>
			!interactionsCurrentState.find(
				(usedInteraction) =>
					usedInteraction['interaction-type'] ===
					interactionType.value['interaction-type'],
			),
	)[0];

	interactionsCurrentState.push({
		...nextInteraction.value,
		id: randomId(),
	});

	updateInteractionsState(interactionsCurrentState);
};
const handleUpdateInteraction = (
	interaction: InteractionType,
	index: number,
) => {
	const interactionsCurrentState = getCurrentInteractionsState();

	interactionsCurrentState[index] = {
		...interaction,
		id: interaction.id == null ? randomId() : interaction.id,
	};

	updateInteractionsState(interactionsCurrentState);
};

const handleRemoveInteraction = (index: number) => {
	const interactionsCurrentState = getCurrentInteractionsState();

	interactionsCurrentState.splice(index, 1);

	updateInteractionsState(interactionsCurrentState);
};

const updateInteractionsState = (newState: InteractionType[]) => {
	useObjectSettingsStore
		.getState()
		.updateAttributeProperty('interactions', newState);
	Component.addAttribute('interactions', newState);
};

export {
	getTags,
	handleAddInteraction,
	handleAddVoiceInput,
	handleRemoveInteraction,
	handleRemoveVoiceInput,
	handleUpdateInteraction,
};

import type { Component } from 'grapesjs';
import { useGeneralSettingStore } from 'store';

const inputVariableDeselectHandler = {
	listenerType: 'component:update' as const,
	handlerCallBack: (model: Component) => {
		if (model.attributes.type !== 'input') return;

		updateApplicationVariables(model);
	},
};

const inputVariableRemoveHandler = {
	listenerType: 'component:remove' as const,
	handlerCallBack: (model: Component) => {
		if (
			model.getAttributes()?.type !== 'input' ||
			!model.getAttributes().variable ||
			!model.ccid
		)
			return;
		useGeneralSettingStore.getState().removeApplicationVariable(model.ccid);
	},
};

export { inputVariableDeselectHandler, inputVariableRemoveHandler };

const updateApplicationVariables = (model: Component) => {
	if (!model.getAttributes().variable) return;
	let updatedVariableValue = '';

	try {
		useGeneralSettingStore.getState().updateApplicationVariable({
			[model.attributes.attributes?.id]: model.getAttributes().variable ?? '',
		});
		updatedVariableValue =
			useGeneralSettingStore.getState().applicationVariables[
				model.attributes.attributes?.id
			];
	} catch (_) {
		updatedVariableValue = '';
	}

	model.addAttributes({
		variable: updatedVariableValue,
	});
};

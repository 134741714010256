import { handleUpdateInteraction } from 'modules/attribute-settings/outcoming-interaction/outcomeInteraction.service';
import { type SUPPORTED_ROBOTS } from 'shared/vars/robots';

import SelectGroup from 'components/select/Select';

type Props = {
	interaction: PromsAppInteractionType;
	index: number;
	robotType: SUPPORTED_ROBOTS;
};

const PromsApp = ({ interaction, index, robotType }: Props) => {
	return (
		<SelectGroup
			label="Select application"
			name="proms-app"
			value={interaction.compoundAppId}
			initialSearch={interaction.compoundAppId?.toString()}
			autoComplete
			apiUrl="/custom/applications"
			apiParams={{ robotType }}
			handleChange={(value: string) => {
				handleUpdateInteraction(
					{
						...interaction,
						compoundAppId: value,
					},
					index,
				);
			}}
		/>
	);
};

export default PromsApp;

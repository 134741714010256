import { type AssignedLocationHandlerTypes } from 'store/use-assign-location/Action';
import { type AssignedLocationTypes } from 'store/use-assign-location/State';
import { create } from 'zustand';

const initialState: AssignedLocationTypes = {
	assignedLocation: '',
	assignedLocationErrorMessage: '',
};

const useAssignLocationStore = create<
	AssignedLocationTypes & AssignedLocationHandlerTypes
>((set) => ({
	...initialState,

	updateAssignedLocation: (assignedLocation) =>
		set(() => ({ assignedLocation })),
	updateAssignedLocationErrorMessage: (assignedLocationErrorMessage) =>
		set(() => ({ assignedLocationErrorMessage })),
}));

export { type AssignedLocationTypes, useAssignLocationStore };

import { getIconPadding, getIconPosition } from './input.service';

import styles from './input.module.css';

type ControlledInputGroupType = {
	id: string;
	name: string;
	value: string;
	label?: string;
	placeholder?: string;
	iconPath?: string;
	type?: string;
	maxLength?: number;
	iconPosition?: 'start' | 'end';
	listenTo?: Array<{
		code: string;
		handleCallBack: Function;
		resetValue?: boolean;
	}>;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur?: (event: React.FocusEvent<HTMLInputElement, Element>) => void;
};

const ControlledInputGroup = ({
	id,
	label,
	placeholder,
	type,
	listenTo,
	iconPath,
	iconPosition,
	name,
	value,
	onChange,
	onBlur,
	maxLength,
}: ControlledInputGroupType) => {
	return (
		<div className="input-group mb-3">
			{label && (
				<label htmlFor={id} className="mb-2">
					{label}
				</label>
			)}
			<span className="position-relative w-100">
				<input
					id={id}
					className={`form-control w-100 rounded no-glow border border-0 inherit-fw bg-white flex-grow-1 ${getIconPadding(
						iconPath,
						iconPosition,
					)}`}
					autoComplete="off"
					placeholder={placeholder}
					type={type ?? 'text'}
					name={name}
					value={value}
					maxLength={maxLength ?? Infinity}
					onChange={onChange}
					onBlur={onBlur}
					onKeyUp={(e) => {
						listenTo?.forEach((event) => {
							if (e.code.includes(event.code)) {
								event.handleCallBack((e.target as HTMLInputElement).value);
								if (event.resetValue) (e.target as HTMLInputElement).value = '';
							}
						});
					}}
				/>
				{iconPath && (
					<span
						className={`position-absolute mx-2 ${
							styles.inputIcon
						} ${getIconPosition(iconPosition)}`}
					>
						<img src={iconPath} alt="input icon" />
					</span>
				)}
			</span>
		</div>
	);
};

export default ControlledInputGroup;

import {
	getSupportRobotFromRobotName,
	SUPPORTED_ROBOTS,
} from 'shared/vars/robots';
import {
	type GeneralSettingHandlerTypes,
	handleRemoveApplicationVariable,
	handleUpdateApplicationVariables,
} from 'store/use-general-settings/Action';
import { type GeneralSettingStateTypes } from 'store/use-general-settings/State';
import { create } from 'zustand';

const initialState: GeneralSettingStateTypes = {
	screenName: '',
	language: '',
	robotType: SUPPORTED_ROBOTS.PEPPER,
	nextScreens: [],
	applicationVariables: {},
	contacts: [],
	locations: [],
	orgId: '',
	assignedMapId: '',
	countryCode: '',
};

const useGeneralSettingStore = create<
	GeneralSettingStateTypes & GeneralSettingHandlerTypes
>((set) => ({
	...initialState,

	updateScreenName: (screenName) => set(() => ({ screenName })),
	updateLanguage: (language) => set(() => ({ language })),
	updateRobotType: (robotName) =>
		set(() => ({ robotType: getSupportRobotFromRobotName(robotName) })),
	updateNextScreens: (nextScreens) => set(() => ({ nextScreens })),
	initializeApplicationVariables: (applicationVariables) =>
		set(() => ({
			applicationVariables,
		})),
	updateApplicationVariable: (applicationVariable) =>
		set((state) => ({
			applicationVariables: handleUpdateApplicationVariables(
				state.applicationVariables,
				applicationVariable,
			),
		})),
	removeApplicationVariable: (componentID) =>
		set((state) => ({
			applicationVariables: handleRemoveApplicationVariable(
				state.applicationVariables,
				componentID,
			),
		})),
	updateContacts: (contacts) => set(() => ({ contacts })),
	updateLocations: (locations) => set(() => ({ locations })),
	updateOrganizationId: (orgId) => set(() => ({ orgId })),
	updateAssignedMapId: (assignedMapId) => set(() => ({ assignedMapId })),
	updateDefaultCountryCode: (countryCode) => set(() => ({ countryCode })),
}));

export { type GeneralSettingStateTypes, useGeneralSettingStore };

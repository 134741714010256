type RadioIconGroupType = {
	id: string;
	label: string;
	radioName: string;
	selectedRadio: string;
	radios: Array<{ iconPath: string; value: string }>;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
const RadioIconGroup = ({
	id,
	label,
	radioName,
	radios,
	selectedRadio,
	onChange,
}: RadioIconGroupType) => {
	return (
		<div className="mb-3">
			<label htmlFor={id}>{label}</label>
			<div id={id} className="d-flex mt-2">
				{radios.map((radio) => {
					return (
						<div key={`${radioName}-${radio.value}`}>
							<input
								className="d-none"
								type="radio"
								id={`${radioName}-${radio.value}`}
								name={radioName}
								value={radio.value}
								onChange={onChange}
								checked={radio.value === selectedRadio}
							/>
							<label
								className={`p-2 rounded align-center m-1 ${
									radio.value === selectedRadio ? 'toggle-btn' : ''
								}`}
								htmlFor={`${radioName}-${radio.value}`}
							>
								<img src={radio.iconPath} alt="Radio icon" />
							</label>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default RadioIconGroup;

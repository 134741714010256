import { getLowercaseStr } from 'utils/functions/strings';

const toOptionType = (array: any[]) => {
	return array.map((item) => ({
		label: item.toString(),
		value: item.toString(),
	})) as OptionType[];
};

const matchOptionTypes = (text: string, options: OptionType[]) => {
	return options.filter((option) => {
		if (!text) return true;
		return getLowercaseStr(option.label).includes(getLowercaseStr(text));
	});
};

const removeByValue = (array: unknown[], removedValue: unknown) => {
	return array.filter((item) => item !== removedValue);
};

const getUniqueArrayItems = (existsArray: unknown[], newValue: unknown) => {
	const uniqueItems = new Set([...existsArray, newValue]);
	return Array.from(uniqueItems);
};

const mapOptionsList = (
	list: any[],
	opts?: { valueKey?: string; labelKey?: string },
) => {
	if (!list) return [];

	const { valueKey, labelKey } = Object.assign(
		{ valueKey: 'id', labelKey: 'name' },
		opts,
	);

	return list.map((item) => {
		return { value: item[valueKey], label: item[labelKey] };
	});
};

export {
	getUniqueArrayItems,
	mapOptionsList,
	matchOptionTypes,
	removeByValue,
	toOptionType,
};

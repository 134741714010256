import { useConfigurationStore } from 'store';

const saveScreen = (body: any) => {
	const { URL } = useConfigurationStore.getState();
	window.parent.postMessage(
		{ type: 'save-screen', screen: body },
		{ targetOrigin: URL },
	);
};

export { saveScreen };

import {
	useAssignLocationStore,
	useConfigurationStore,
	useGeneralSettingStore,
	useKeywordStore,
	useMaskDetectionStore,
	useScreenTransitionStore,
	useSensoryInteractionStore,
	useVoiceOverStore,
} from 'store';
import { mapOptionsList } from 'utils/functions/arrays';

const handleWindowMessageCallback = (
	message: MessageEvent,
	setUnlockApp: React.Dispatch<
		React.SetStateAction<{
			message: boolean;
		}>
	>,
) => {
	if (
		message.data.type === 'initialize' ||
		message.data.type === 'new-screen'
	) {
		const screenSettings = message.data.screenData;
		initializeConfigurations(message.data?.origin ?? '/');
		initializeGeneralSettings(
			screenSettings?.name ?? '',
			screenSettings?.language ?? 'en',
			(message.data?.robotType ?? '') as string,
			(message.data?.orgId ?? '') as string,
			mapOptionsList(message.data?.nextScreens),
			message.data?.applicationVariables ?? {},
			mapOptionsList(message.data?.contacts),
			mapOptionsList(message.data?.locations),
			message.data?.assignedMapId ?? '',
			message.data?.countryCode ?? '',
		);

		initializeEditor(
			screenSettings?.components ?? '[]',
			screenSettings?.styles ?? '[]',
		);

		initializeKeywords(screenSettings?.keywords ?? []);

		initializeMaskDetection(
			screenSettings?.isMask ?? false,
			screenSettings?.maskMessage ?? '',
		);

		initializeLocationAssignment(
			screenSettings?.assignedLocation ?? '',
			screenSettings?.assignedLocationErrorMessage ?? '',
		);

		initializeVoiceOver(
			screenSettings?.output ?? '',
			screenSettings?.error ?? '',
			screenSettings?.gesture ?? '',
		);

		initializeScreenTransition(
			screenSettings?.isTransition ?? false,
			screenSettings?.duration ?? '',
			screenSettings?.transition ?? '',
		);

		initializeSensoryInteraction(
			screenSettings?.headSensorAction ?? '',
			screenSettings?.headSensorValue ?? '',
			screenSettings?.handSensorAction ?? '',
			screenSettings?.handSensorValue ?? '',
		);

		initializeToken(message.data?.token ?? '');

		setUnlockApp((state) => ({ ...state, message: true }));
	}
};

export { handleWindowMessageCallback };

const initializeConfigurations = (origin: string) => {
	useConfigurationStore.getState().updateConfigurations(origin);
};

const initializeGeneralSettings = (
	name: string,
	language: string,
	robotName: string,
	orgId: string,
	nextScreens: OptionType[],
	applicationVariables: Record<string, string>,
	contacts: OptionType[],
	locations: OptionType[],
	assignedMapId: string,
	countryCode: string,
) => {
	useGeneralSettingStore.getState().updateScreenName(name);
	useGeneralSettingStore.getState().updateLanguage(language);
	useGeneralSettingStore.getState().updateRobotType(robotName);
	useGeneralSettingStore.getState().updateOrganizationId(orgId);
	useGeneralSettingStore.getState().updateNextScreens(nextScreens);
	useGeneralSettingStore
		.getState()
		.initializeApplicationVariables(applicationVariables);
	useGeneralSettingStore.getState().updateContacts(contacts);
	useGeneralSettingStore.getState().updateLocations(locations);
	useGeneralSettingStore.getState().updateAssignedMapId(assignedMapId);
	useGeneralSettingStore.getState().updateDefaultCountryCode(countryCode);
};

const initializeEditor = (components: string, styles: string) => {
	const editor = {
		components: JSON.parse(components),
		styles: JSON.parse(styles),
	};

	window.localStorage.setItem('screen-editor', JSON.stringify(editor));
};

const initializeKeywords = (keywords: string[]) => {
	useKeywordStore.getState().addKeywords(keywords);
};

const initializeMaskDetection = (
	hasMask: boolean,
	detectionMessage: string,
) => {
	useMaskDetectionStore.getState().updateHasMaskDetection(hasMask);
	useMaskDetectionStore.getState().updateMaskDetectionMessage(detectionMessage);
};

const initializeLocationAssignment = (
	assignedLocation: string,
	assignedLocationErrorMessage: string,
) => {
	useAssignLocationStore.getState().updateAssignedLocation(assignedLocation);
	useAssignLocationStore
		.getState()
		.updateAssignedLocationErrorMessage(assignedLocationErrorMessage);
};

const initializeVoiceOver = (
	welcomeMessage: string,
	errorMessage: string,
	gesture: string,
) => {
	useVoiceOverStore.getState().updateWelcomeMessage(welcomeMessage);
	useVoiceOverStore.getState().updateErrorMessage(errorMessage);
	useVoiceOverStore.getState().updateGesture(gesture);
};

const initializeScreenTransition = (
	hasScreenTransition: boolean,
	trigger: string,
	transitionTo: string,
) => {
	useScreenTransitionStore
		.getState()
		.updateHasScreenTransition(hasScreenTransition);
	useScreenTransitionStore.getState().updateTrigger(trigger);
	useScreenTransitionStore.getState().updateTransitionTo(transitionTo);
};

const initializeSensoryInteraction = (
	headSensorAction: string,
	headSensorValue: string,
	handSensorAction: string,
	handSensorValue: string,
) => {
	useSensoryInteractionStore
		.getState()
		.updateHeadSensorAction(headSensorAction);
	useSensoryInteractionStore.getState().updateHeadSensorValue(headSensorValue);
	useSensoryInteractionStore
		.getState()
		.updateHandSensorAction(handSensorAction);
	useSensoryInteractionStore.getState().updateHandSensorValue(handSensorValue);
};

const initializeToken = (token: string) => {
	useConfigurationStore.getState().updateToken(token);
};

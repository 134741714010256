import { handleDisplayAutocomplete } from 'components/autocomplete/autocomplete.service';

const handleEditableParagraph = (
	event: React.FormEvent<HTMLParagraphElement>,
	handleChange: (changedText: string) => void,
	autocompleteOptions?: string[],
) => {
	const targetEl = event.target as HTMLElement;
	handleChange(targetEl.innerText);
	autocompleteOptions &&
		handleDisplayAutocomplete(
			event.target as HTMLElement,
			autocompleteOptions,
			(selectedOption) => {
				targetEl.innerHTML += String(selectedOption);
				handleChange(targetEl.innerHTML);
			},
			targetEl.innerText,
		);
};

export { handleEditableParagraph };

enum SUPPORTED_ROBOTS {
	PEPPER = 'PEPPER',
	TEMI = 'TEMI',
}

const getSupportRobotFromRobotName = (robotName: string) => {
	switch (robotName.toUpperCase()) {
		case 'PEPPER':
			return SUPPORTED_ROBOTS.PEPPER;
		case 'TEMI':
			return SUPPORTED_ROBOTS.TEMI;
		default:
			return SUPPORTED_ROBOTS.PEPPER;
	}
};

export { getSupportRobotFromRobotName, SUPPORTED_ROBOTS };

import type { AddComponentTypeOptions, Editor } from 'grapesjs';

const addTextComponentPlugin = (editor: Editor) => {
	const componentsOptions: AddComponentTypeOptions = {
		isComponent: (el: HTMLElement) => el.tagName === 'P',
		extend: 'text',
		model: {
			defaults: {
				tagName: 'p',
				resizable: true,
				attributes,
				traits: [],
				components: [{ type: 'textnode', content: 'Text Field' }],
			},
		},
		view: {
			events: () => ({
				dblclick: 'onActive',
				keydown: 'onKeyDown',
				paste: 'onpaste',
				input: 'onInput',
				dragstart: 'handleDragStart',
			}),
			init() {
				const styles = {
					...style,
					...this.model.getStyle(),
				};

				if (this.model.attributes.attributes?.sizeChanged === false) {
					styles.width = 'max-content';
					styles.height = 'max-content';
				}

				this.model.setStyle(styles);
			},
			onpaste(e: any) {
				e.preventDefault();

				const text = (e.originalEvent || e).clipboardData.getData('text/plain');
				e.target.ownerDocument.execCommand(
					'insertHtml',
					false,
					text.replaceAll(/[\r\n]+/g, '<br>'),
				);

				if ((e.originalEvent || e).clipboardData.items.length === 1) {
					e.target.ownerDocument.execCommand('undo', false);
				}
			},
			onKeyDown(e: any) {
				const preventKeyCodes = [66, 98, 73, 105, 85, 117, 13];

				if (e.ctrlKey && preventKeyCodes.includes(e.keyCode)) return false;

				return true;
			},
		},
	};
	editor.DomComponents.addType('text', componentsOptions);
};

export { addTextComponentPlugin };

const style = {
	height: 'max-content',
	width: 'max-content',
	'word-break': 'break-word',
	'border-color': '#ffffff',
};
const attributes = {
	type: 'text',
	sizeChanged: false,
	backgroundImage: false,
};

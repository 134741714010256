import { type objectSettingsHandlerTypes } from 'store/use-object-settings/Action';
import { type ObjectSettingsStateTypes } from 'store/use-object-settings/State';
import { create } from 'zustand';

const initialState: ObjectSettingsStateTypes = {
	sectors: [],
	componentStyles: {},
	componentAttributes: {},
	object: undefined,
};

const useObjectSettingsStore = create<
	ObjectSettingsStateTypes & objectSettingsHandlerTypes
>((set) => ({
	...initialState,

	updateSectors: (sectors) =>
		set(() => ({
			sectors,
		})),

	updateStyles: (componentStyles) =>
		set(() => ({
			componentStyles,
		})),
	updateAttributes: (componentAttributes) =>
		set(() => ({
			componentAttributes,
		})),
	updateStyleProperty: (propertyName, value) =>
		set((state) => {
			const componentStyles = { ...state.componentStyles };
			componentStyles[propertyName] = value;
			return { ...state, componentStyles };
		}),
	updateAttributeProperty: (propertyName, value) =>
		set((state) => {
			const componentAttributes = { ...state.componentAttributes };
			componentAttributes[propertyName] = value;
			return { ...state, componentAttributes };
		}),
	updateObject: (object) => set(() => ({ object })),
}));

export { type ObjectSettingsStateTypes, useObjectSettingsStore };

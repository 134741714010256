import { handleUpdateInteraction } from 'modules/attribute-settings/outcoming-interaction/outcomeInteraction.service';
import { useGeneralSettingStore } from 'store';

import SelectGroup from 'components/select/Select';

type Props = {
	interaction: RobotToRobotInteractionType;
	index: number;
};
const RobotToRobot = ({ interaction, index }: Props) => {
	const [orgId] = useGeneralSettingStore((state) => [state.orgId]);
	return (
		<>
			<SelectGroup
				label="Select Robot"
				name="r2r-robot-id"
				value={interaction['target-robot-id']}
				apiUrl="/items/robots"
				apiParams={{
					fields: ['name', 'android_id'],
					filter: { organization_id: { _eq: orgId } },
				}}
				optionsListParams={{ valueKey: 'android_id' }}
				handleChange={(value: string) => {
					handleUpdateInteraction(
						{
							...interaction,
							'target-robot-id': value,
							'target-app-id': '',
							'target-transition-screen': '',
						},
						index,
					);
				}}
			/>

			<SelectGroup
				label="Select Application"
				name="target-app-id"
				value={interaction['target-app-id']}
				apiUrl="items/applications"
				apiParams={{
					fields: ['id', 'name'],
					filter: {
						organization_id: { _eq: orgId },
						robots: {
							robots_android_id: { _eq: interaction['target-robot-id'] },
						},
					},
				}}
				disable={!interaction['target-robot-id']}
				handleChange={(value: string) => {
					handleUpdateInteraction(
						{
							...interaction,
							'target-app-id': value.toString(),
							'target-transition-screen': '',
						},
						index,
					);
				}}
			/>
			<SelectGroup
				label="Select Screen"
				name="target-transition-screen"
				value={interaction['target-transition-screen']}
				apiUrl="items/screens"
				apiParams={{
					fields: ['id', 'name'],
					filter: {
						organization_id: { _eq: orgId },
						application_id: { _eq: interaction['target-app-id'] },
					},
				}}
				disable={!interaction['target-app-id']}
				handleChange={(value: string) => {
					handleUpdateInteraction(
						{
							...interaction,
							'target-transition-screen': value.toString(),
						},
						index,
					);
				}}
			/>
		</>
	);
};

export default RobotToRobot;

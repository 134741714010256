import { ARROW_ICON_PATH, ROOT_ICON_PATH } from 'shared/vars/path';

const sensorOptions: GenericOptionType[] = [
	{ label: 'None', value: '', icon: `${ROOT_ICON_PATH}/close.svg` },
	{
		label: 'To Screen',
		value: 'screen-transition',
		icon: `${ARROW_ICON_PATH}/down-left-arrow.svg`,
	},
	{ label: 'Gestures', value: 'gesture', icon: `${ROOT_ICON_PATH}/hand.svg` },
	{
		label: 'To Application',
		value: 'app',
		icon: `${ARROW_ICON_PATH}/pop-out.svg`,
	},
];

export { sensorOptions };

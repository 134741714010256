import { create } from 'zustand';

import { type globalEventsHandlerTypes } from './Action';
import { type globalEventsStateTypes } from './State';

const initialState: globalEventsStateTypes = {
	isFullScreenOn: false,
	isDirtyEditor: false,
};

const useGlobalEventsStore = create<
	globalEventsStateTypes & globalEventsHandlerTypes
>((set) => ({
	...initialState,
	toggleFullScreen: (isFullScreenOn) => {
		set(() => ({ isFullScreenOn }));
	},
	markAsDirtyEditor: (isDirtyEditor = true) => set(() => ({ isDirtyEditor })),
}));

export { type globalEventsStateTypes, useGlobalEventsStore };

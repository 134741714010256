import type { Component } from 'grapesjs';
import { randomId } from 'utils/functions/strings';

const clonedComponentsHandler = {
	listenerType: 'component:clone' as const,
	handlerCallBack: (model: Component) => {
		generateInteractionId(model);
	},
};

export { clonedComponentsHandler };

const generateInteractionId = (model: Component) => {
	const interactions = structuredClone(
		model.getAttributes().interactions || [],
	).map((interaction: InteractionType) => {
		interaction.id = randomId();

		return interaction;
	});

	model.setAttributes({ ...model.getAttributes(), interactions });
};

import { type LegacyRef } from 'react';

import { getIconPadding, getIconPosition } from './input.service';

import styles from './input.module.css';

type InputGroupType = {
	id: string;
	label: string;
	ref?: LegacyRef<HTMLInputElement>;
	placeholder?: string;
	iconPath?: string;
	type?: string;
	listenTo?: Array<{
		code: string;
		handleCallBack: Function;
		resetValue?: boolean;
	}>;
	iconPosition?: 'start' | 'end';
};

const InputGroup = ({
	id,
	label,
	ref,
	placeholder,
	type,
	listenTo,
	iconPath,
	iconPosition,
}: InputGroupType) => {
	return (
		<div className="input-group mb-3">
			<label htmlFor={id} className="mb-2">
				{label}
			</label>
			<span className="position-relative w-100">
				<input
					id={id}
					className={`form-control w-100 rounded no-glow border border-0 inherit-fw bg-white flex-grow-1 ${getIconPadding(
						iconPath,
						iconPosition,
					)}`}
					placeholder={placeholder}
					autoComplete="off"
					ref={ref}
					type={type ?? 'text'}
					onKeyUp={(e) => {
						listenTo?.forEach((event) => {
							if (e.code?.includes(event.code)) {
								event.handleCallBack((e.target as HTMLInputElement).value);
								if (event.resetValue) (e.target as HTMLInputElement).value = '';
							}
						});
					}}
				/>
				{iconPath && (
					<span
						className={`position-absolute mx-2 ${
							styles.inputIcon
						} ${getIconPosition(iconPosition)}`}
					>
						<img src={iconPath} alt="input icon" />
					</span>
				)}
			</span>
		</div>
	);
};

export default InputGroup;

import { AUTO_COMPLETE_TRIGGER } from 'shared/vars/constantLiterals';
import { useAutocompleteStore } from 'store/use-autocomplete';
import { toOptionType } from 'utils/functions/arrays';
import { isClickOutsideElement } from 'utils/functions/document';
import { isStrEndWith } from 'utils/functions/strings';

let globalParentEl: HTMLElement, globalCanvasEl: HTMLElement | undefined;
let globalOptions: any[];
let globalChosenOption: (option: unknown) => void;

function handleDisplayAutocomplete(
	parentEl: HTMLElement,
	options: any[],
	chosenOption: (option: unknown) => void,
	updatedText: string,
	canvasEl?: HTMLElement,
) {
	initializeGlobalVariables(parentEl, options, chosenOption, canvasEl);
	updateAutocompleteState();
	listenForElementChanges(updatedText);
}

const handleChooseAutocompleteOption = (value: unknown) => {
	const lastOpenedAutocomplete = globalParentEl.innerHTML.lastIndexOf(
		AUTO_COMPLETE_TRIGGER.OPEN,
	);
	globalParentEl.innerHTML = globalParentEl.innerHTML.substring(
		0,
		lastOpenedAutocomplete,
	);
	useAutocompleteStore
		.getState()
		.chosenOption(getAutocompleteParsedValue(value));
	unmountAutocompleteComponent();
};

const handleClickOutsideAutocomplete = (
	clickEvent: MouseEvent,
	autocompleteContainerId: string,
) => {
	if (isClickOutsideElement(autocompleteContainerId, clickEvent))
		unmountAutocompleteComponent();
};

const unmountAutocompleteComponent = () => {
	useAutocompleteStore.getState().updateDisplay('none');
};

export {
	handleChooseAutocompleteOption,
	handleClickOutsideAutocomplete,
	handleDisplayAutocomplete,
	unmountAutocompleteComponent,
};

const initializeGlobalVariables = (
	parentEl: HTMLElement,
	options: any[],
	chosenOption: (option: unknown) => void,
	canvasEl?: HTMLElement,
) => {
	globalParentEl = parentEl;
	globalCanvasEl = canvasEl ?? undefined;
	globalOptions = options;
	globalChosenOption = chosenOption;
};

const getAutocompleteParsedValue = (value: unknown) => {
	if (typeof value === 'string')
		return `${AUTO_COMPLETE_TRIGGER.OPEN}${value.toString()}${
			AUTO_COMPLETE_TRIGGER.CLOSE
		}`;
};

const updateAutocompleteState = () => {
	useAutocompleteStore.getState().updateOptions(toOptionType(globalOptions));
	useAutocompleteStore.getState().updateText('');
	useAutocompleteStore.getState().updateChosenOption(globalChosenOption);
};

const listenForElementChanges = (text: string) => {
	if (shouldShowAutocomplete(text)) {
		updateAutocompletePosition(globalParentEl, globalCanvasEl);
		useAutocompleteStore.getState().updateDisplay('inline-block');
	}

	updateAutocompleteChangedText(text);
	if (shouldHideAutocomplete()) unmountAutocompleteComponent();
};

const shouldShowAutocomplete = (text?: string, booleanExpression?: boolean) => {
	return (
		(booleanExpression ?? true) &&
		useAutocompleteStore.getState().display === 'none' &&
		isStrEndWith(text ?? globalParentEl.innerText, AUTO_COMPLETE_TRIGGER.OPEN)
	);
};

const shouldHideAutocomplete = () => {
	return (
		useAutocompleteStore.getState().display === 'inline-block' &&
		isStrEndWith(globalParentEl.innerText, AUTO_COMPLETE_TRIGGER.CLOSE)
	);
};

const updateAutocompleteChangedText = (newText: string) => {
	const lastAutocompleteTriggerIndex =
		newText.lastIndexOf(AUTO_COMPLETE_TRIGGER.OPEN) + 2;

	useAutocompleteStore
		.getState()
		.updateText((newText.substring(lastAutocompleteTriggerIndex) ?? '').trim());
};

const updateAutocompletePosition = (
	parentEl: HTMLElement,
	canvasEl?: HTMLElement,
) => {
	const iframeCoordinates = getIframeCoordinates(canvasEl);
	const autocompleteApproximateCoordinates =
		getAutocompleteApproximateCoordinates(parentEl, iframeCoordinates);
	useAutocompleteStore
		.getState()
		.updatePosition(autocompleteApproximateCoordinates);
};

const getIframeCoordinates = (iframeEl?: HTMLElement) => {
	if (!iframeEl) return undefined;
	const iframeCoordinates = iframeEl.getBoundingClientRect();
	return iframeCoordinates;
};

const getAutocompleteApproximateCoordinates = (
	parentEl: HTMLElement,
	offset?: DOMRect,
) => {
	const basicCoordinates = getElCoordinates(parentEl);
	let left = basicCoordinates.left + (offset ? offset.left : 0);
	if (left + 250 > window.innerWidth) left = window.innerWidth - 250; // 250 is the autocomplete width
	return {
		left,
		top:
			(offset
				? basicCoordinates.bottom + basicCoordinates.height + offset.top + 20
				: basicCoordinates.top) + 20,
	};
};

const getElCoordinates = (parentEl: HTMLElement) => {
	const span = document.createElement('span');
	parentEl.insertAdjacentElement('beforeend', span);
	const coordinates = span.getBoundingClientRect();
	span.remove();
	return coordinates;
};

import { type IEditor } from 'editor/common/interfaces/IEditor';
import { getEditorInitialObject } from 'editor/grapes-js/config/setup/editorInitialObject';
import { defaultPlugins } from 'editor/grapes-js/config/setup/plugins.config';
import type {
	BlockEvent,
	BlockProperties,
	Component,
	ComponentEvent,
	Editor,
} from 'grapesjs';
import GrapesJs from 'grapesjs';
import { useGlobalEventsStore } from 'store';

type Positions = {
	container: string;
	blocks: string;
	layers: string;
	styles: string;
};

class GrapesEditor implements IEditor {
	private readonly _editor: Editor;

	constructor(positions: Positions, plugins: any[]) {
		this._editor = GrapesJs.init({
			...getEditorInitialObject(
				positions.container,
				positions.blocks,
				positions.layers,
			),
			plugins: [...defaultPlugins, ...plugins],
		});
	}

	getEditor() {
		return this;
	}

	destroyEditor() {
		this._editor.destroy();
	}

	removePanels(panels: string[]) {
		panels.forEach((panel) => {
			this._editor.Panels.removePanel(panel);
		});
		return this;
	}

	removeDefaultRteTooltip() {
		const richTextEditor = this._editor.RichTextEditor;
		const removedItemsFromRte = [
			'bold',
			'italic',
			'underline',
			'strikethrough',
			'link',
			'wrap',
		];
		removedItemsFromRte.forEach((item) => richTextEditor.remove(item));
		return this;
	}

	resetBlocks() {
		this._editor.BlockManager.getAll().reset();
	}

	registerBlocks(blocks: Array<{ id: string; options: BlockProperties }>) {
		this.resetBlocks();

		blocks.forEach((block) => {
			this._editor.BlockManager.add(block.id, block.options);
		});
		return this;
	}

	registerComponentUpdateListeners(
		handlers: Array<{
			handlerCallBack: (model?: any) => void;
			listenerType: ComponentEvent | BlockEvent;
		}>,
	) {
		handlers.forEach((handler) => {
			this._editor.on(handler.listenerType, handler.handlerCallBack);
		});
		return this;
	}

	exportComponents() {
		const stringifiedComponents = JSON.stringify(this._editor.getComponents());
		return JSON.parse(stringifiedComponents);
	}

	exportStyles() {
		const styles = this._editor.getStyle().map((style) => {
			const model = this._editor
				.getWrapper()
				?.find(style.selectorsToString())[0];

			if (model?.getName() !== 'Text') return style;

			const el = model?.getEl();

			if (!el) return style;

			const sizeChanged =
				style.attributes.style?.width !== 'max-content' &&
				style.attributes.style?.height !== 'max-content';

			model.setAttributes({ ...model.getAttributes(), sizeChanged });

			if (sizeChanged) return style;

			const { height, width } = el.getBoundingClientRect();

			style.addStyle('height', `${height}px`);
			style.addStyle('width', `${width}px`);

			return style;
		});

		return JSON.parse(JSON.stringify(styles));
	}

	setZoomLevel(zoomLevel: number) {
		this._editor.Canvas.setZoom(zoomLevel);
	}

	addAssets(assets: object[]) {
		this._editor.AssetManager.add(assets);
	}

	onLoadEditor() {
		this._editor.on('load', () => {
			this._editor
				.getWrapper()
				?.addAttributes({ id: 'wrapper', backgroundImage: true });

			this.removeDuplicateCssRules();
		});

		return this;
	}

	restrictPosition(e?: { target: any }) {
		const target = e?.target || (this._editor.getSelected() as Component) || e;

		if (!target) return;

		const canvasRect = this._editor.Canvas.getRect();

		const { top, left, height, width } = (
			target.view?.el || target.view.$el[0]
		).getBoundingClientRect() as DOMRect;

		// horizontal

		if (left < 0) {
			target.setStyle({
				...target.getStyle(),
				left: '0px',
			});
		}
		if (left + width > canvasRect.width) {
			target.setStyle({
				...target.getStyle(),
				left: `${canvasRect.width - width}px`,
			});
		}

		// vertical

		if (top + height > canvasRect.height) {
			target.setStyle({
				...target.getStyle(),
				top: `${Math.max(canvasRect.height - height, 0)}px`,
				height: `${Math.max(
					Math.min(height, canvasRect.height),
					canvasRect.height - top,
				)}px`,
			});
		}

		if (top < 0) {
			target.setStyle({
				...target.getStyle(),
				top: '0px',
				height: `${Math.min(canvasRect.height, height)}px`,
			});
		}
	}

	removeDuplicateCssRules() {
		this._editor.getComponents().forEach((component) => {
			const cssRules = this._editor.Css.getRules(`#${component.getId()}`);

			if (cssRules.length >= 2)
				cssRules.slice(1).forEach((rule) => this._editor.Css.remove(rule));
		});
	}

	detectDirtyEditor() {
		const markAsDirtyEditor = () =>
			useGlobalEventsStore.getState().markAsDirtyEditor();

		this._editor.once('canvas:dragend', markAsDirtyEditor);
		this._editor.once('component:selected', markAsDirtyEditor);

		return this;
	}

	openModal(options: { select: (asset: any, complete: any) => void }) {
		this._editor.AssetManager.open(options);
	}

	closeModal() {
		this._editor.AssetManager.close();
	}

	renderEditor() {
		this._editor.render();
		return this;
	}
}

export { GrapesEditor };

import { differenceWith, remove } from 'lodash';
import { SUPPORTED_ROBOTS } from 'shared/vars/robots';

const interactionTypes: InteractionOptionType[] = [
	{
		label: 'Screen transition',
		value: {
			id: null,
			'interaction-type': 'screen-transition',
			'transition-screen': '',
			'voice-input': [],
		},
		key: 'screen-transition',
	},
	{
		label: '3rd party APK',
		value: { id: null, 'interaction-type': 'apk-deeplink', 'apk-deeplink': '' },
		key: '3rd-party-app',
	},
	{
		label: 'Robot To Robot',
		value: {
			id: null,
			'interaction-type': 'r2r',
			'target-robot-id': '',
			'target-app-id': '',
			'target-transition-screen': '',
		},
		key: 'r2r',
	},
	{
		label: 'PRoMS app',
		value: {
			id: null,
			'interaction-type': 'proms-app',
			compoundAppId: null,
		},
		key: 'proms-app',
	},
	{
		label: 'Telepresence',
		value: {
			id: null,
			'interaction-type': 'telepresence',
			contact: '',
		},
		key: 'telepresence',
	},
	{
		label: 'Send SMS',
		value: {
			id: null,
			'interaction-type': 'send-sms',
			'country-code': '',
			'phone-number': '',
		},
		key: 'send-sms',
	},
];

const filterInteractionTypes = (
	usedInteractionTypes: InteractionType[],
	currentInteraction: InteractionType,
	robotType: string,
) => {
	remove(interactionTypes, (interactionType: InteractionOptionType) => {
		return (
			SUPPORTED_ROBOTS.TEMI !== robotType &&
			interactionType.key === 'telepresence'
		);
	});

	return differenceWith(
		interactionTypes,
		usedInteractionTypes,
		(
			interactionType: InteractionOptionType,
			usedInteraction: InteractionType,
		) => {
			return (
				interactionType.value['interaction-type'] ===
					usedInteraction['interaction-type'] &&
				usedInteraction['interaction-type'] !==
					currentInteraction['interaction-type']
			);
		},
	);
};

export { filterInteractionTypes, interactionTypes };

const fontFamilies = [
	{ label: 'Default', value: '' },
	{ label: 'Times New Roman', value: 'Times New Roman' },
	{ label: 'Roboto', value: 'Roboto' },
	{ label: 'Arial', value: 'Arial' },
	{ label: 'Helvetica', value: 'Helvetica' },
	{ label: 'Verdana', value: 'Verdana' },
	{ label: 'Georgia', value: 'Georgia' },
	{ label: 'Impact', value: 'Impact' },
	{ label: 'Tahoma', value: 'Tahoma' },
	{ label: 'Noto Sans', value: 'Noto Sans' },
	{ label: 'Droid Sans', value: 'Droid Sans' },
	{ label: 'Open Sans', value: 'Open Sans' },
	{ label: 'Courier New', value: 'Courier New' },
	{ label: 'Lucida Console', value: 'Lucida Console' },
	{ label: 'Comic Sans MS', value: 'Comic Sans MS' },
	{ label: 'Trebuchet MS', value: 'Trebuchet MS' },
];

const fontWeights = [
	{
		value: 'normal',
		label: 'Normal',
	},
	{
		value: 'bold',
		label: 'Bold',
	},
	{
		value: 'bolder',
		label: 'Bolder',
	},
	{
		value: 'lighter',
		label: 'Lighter',
	},
];

export { fontFamilies, fontWeights };

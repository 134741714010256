interface KeywordHandlerTypes {
	addKeyword: (keyword: string) => void;
	addKeywords: (keywords: string[]) => void;
	removeKeyword: (keyword: string) => void;
}

const combineUniqueKeywordsFromTwoList = (
	listOne: string[],
	listTwo: string[],
) => {
	return Array.from(new Set([...listOne, ...listTwo]));
};

const removeKeywordFromList = (list: string[], keyword: string) => {
	return list.filter((item: string) => item !== keyword);
};

export {
	combineUniqueKeywordsFromTwoList,
	type KeywordHandlerTypes,
	removeKeywordFromList,
};

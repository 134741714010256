import { languageOptions } from 'shared/options/languages';
import { useGeneralSettingStore } from 'store';

import Sector from 'components/sector/Sector';
import SelectGroup from 'components/select/Select';

const Language = () => {
	const [language, updateLanguage] = useGeneralSettingStore((state) => [
		state.language,
		state.updateLanguage,
		state.nextScreens,
		state.locations,
	]);
	return (
		<Sector title="Language selection">
			<SelectGroup
				label=""
				options={languageOptions}
				value={language}
				handleChange={updateLanguage}
			/>
		</Sector>
	);
};

export default Language;

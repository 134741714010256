/* eslint max-lines: 0 */
const gestureOptions: GenericOptionType[] = [
	{ label: '-- None --', value: '' },
	{ label: 'Show body', value: 'show_body_a001' },
	{ label: 'Show bumper right', value: 'show_bumper_right_a001' },
	{ label: 'Show hand both', value: 'show_hand_both_a001' },
	{ label: 'Show hand left', value: 'show_hand_left_a001' },
	{ label: 'Show head', value: 'show_head_a001' },
	{ label: 'Show micro', value: 'show_micro_a001' },
	{ label: 'Show self', value: 'show_self_a001' },
	{ label: 'Show shoulder', value: 'show_shoulders_a001' },
	{ label: 'Check time left', value: 'check_time_left_b001' },
	{ label: 'Check time right', value: 'check_time_right_b001' },
	{ label: 'Drink left', value: 'drink_left_b001' },
	{ label: 'Drink right', value: 'drink_right_b001' },
	{ label: 'Listen left', value: 'listen_left_b001' },
	{ label: 'Listen right', value: 'listen_right_b001' },
	{ label: 'Looking up left hand', value: 'looking_up_left_hand_b001' },
	{ label: 'Looking up right hand', value: 'looking_up_right_hand_b001' },
	{ label: 'Napping left', value: 'napping_left_b001' },
	{ label: 'Napping right', value: 'napping_right_b001' },
	{ label: 'Open drink left', value: 'open_drink_left_b001' },
	{ label: 'Open drink right', value: 'open_drink_right_b001' },
	{ label: 'Take pic', value: 'take_pic_b001' },
	{ label: 'Walk run', value: 'walk_run_b001' },
	{ label: 'washing arms', value: 'washing_arms_b001' },
	{ label: 'Flick down left hand', value: 'flick_down_left_hand_b001' },
	{
		label: 'Flick down right hand',
		value: 'flick_down_right_hand_b001',
	},
	{
		label: 'Flick left left hand',
		value: 'flick_left_left_hand_b001',
	},
	{
		label: 'Flick right right hand',
		value: 'flick_right_right_hand_b001',
	},
	{
		label: 'Gesture both hands',
		value: 'gesture_both_hands_b001',
	},
	{
		label: 'Gesture left hand',
		value: 'gesture_left_hand_b001',
	},
	{
		label: 'Gesture right hand',
		value: 'gesture_right_hand_b001',
	},
	{ label: 'Show tablet', value: 'show_tablet_a001' },
	{
		label: 'Tap display left hand',
		value: 'tap_display_left_hand_b001',
	},
	{
		label: 'tap display right hand',
		value: 'tap_display_right_hand_b001',
	},
	{
		label: 'Both hands in front',
		value: 'both_hands_in_front_b001',
	},
	{
		label: 'Both hands on hips',
		value: 'both_hands_on_hips_b001',
	},
	{ label: 'Raise both hands', value: 'raise_both_hands_b001' },
	{
		label: 'spread both hands',
		value: 'spread_both_hands_b001',
	},
	{
		label: 'Left hand to chest',
		value: 'left_hand_to_chest_b001',
	},
	{
		label: 'Left hand to mouth',
		value: 'left_hand_to_mouth_b001',
	},
	{ label: 'Raise left hand', value: 'raise_left_hand_b001' },
	{ label: 'Raise right hand', value: 'raise_right_hand_b001' },
	{
		label: 'Right hand to chest',
		value: 'right_hand_to_chest_b001',
	},
	{
		label: 'Right hand to mouth',
		value: 'right_hand_to_mouth_b001',
	},
	{ label: 'Annoyed', value: 'annoyed_b001' },
	{ label: 'Bored', value: 'bored_a001' },
	{ label: 'Confused', value: 'confused_a001' },
	{ label: 'Dizzy', value: 'dizzy_a001' },
	{ label: 'Evil', value: 'evil_a001' },
	{ label: 'Fear', value: 'fear_a001' },
	{ label: 'Funny', value: 'funny_a001' },
	{ label: 'Furious', value: 'furious_a001' },
	{ label: 'Sad', value: 'sad_a001' },
	{ label: 'Saddened', value: 'saddened_b001' },
	{
		label: 'Scratch back of head left',
		value: 'scratch_back_of_head_left_b001',
	},
	{
		label: 'Scratch back of head right',
		value: 'scratch_back_of_head_right_b001',
	},
	{
		label: 'Scratch top of head left',
		value: 'scratch_top_of_head_left_b001',
	},
	{
		label: 'Scratch top of head right',
		value: 'scratch_top_of_head_right_b001',
	},
	{ label: 'Surprised', value: 'surprised_a001' },
	{
		label: 'Tap top of head left',
		value: 'tap_top_of_head_left_b001',
	},
	{
		label: 'Tap top of head right',
		value: 'tap_top_of_head_right_b001',
	},
	{ label: 'Tickling', value: 'tickling_a001' },
	{ label: 'worried', value: 'worried_a001' },
	{ label: 'Bow', value: 'bow_a001' },
	{ label: 'Cautious', value: 'cautious_a001' },
	{ label: 'Chill', value: 'chill_a001' },
	{ label: 'Coughing left', value: 'coughing_left_b001' },
	{ label: 'Coughing right', value: 'coughing_right_b001' },
	{ label: 'Curious', value: 'curious_a001' },
	{ label: 'Deep breath', value: 'deep_breath_b001' },
	{ label: 'Itchi', value: 'itchi_a001' },
	{ label: 'Melancholic', value: 'melancholic_a001' },
	{ label: 'Searching', value: 'searching_a001' },
	{ label: 'Shy', value: 'shy_b001' },
	{ label: 'Sneeze', value: 'sneeze_b001' },
	{ label: 'Sniffing', value: 'sniffing_b001' },
	{ label: 'Thinking', value: 'thinking_a001' },
	{ label: 'Both hands high', value: 'both_hands_high_b001' },
	{ label: 'Both hands low', value: 'both_hands_low_b001' },
	{ label: 'Cheers', value: 'cheers_b001' },
	{ label: 'Clapping', value: 'clapping_b001' },
	{ label: 'Kisses', value: 'kisses_a001' },
	{ label: 'Left hand high', value: 'left_hand_high_b001' },
	{ label: 'Left hand low', value: 'left_hand_low_b001' },
	{ label: 'Nicereaction', value: 'nicereaction_a001' },
	{ label: 'Right hand high', value: 'right_hand_high_b001' },
	{ label: 'Right hand low', value: 'right_hand_low_b001' },
	{ label: 'yeah', value: 'yeah_b001' },
	{ label: 'Affirmation', value: 'affirmation_a001' },
	{
		label: 'Affirmation right hand',
		value: 'affirmation_right_hand_a001',
	},
	{ label: 'certain', value: 'certain_b001' },
	{
		label: 'Enumeration both hand',
		value: 'enumeration_both_hand_a001',
	},
	{
		label: 'Enumeration left hand',
		value: 'enumeration_left_hand_a001',
	},
	{
		label: 'enumeration right hand',
		value: 'enumeration_right_hand_a001',
	},
	{
		label: 'Exclamation both hands',
		value: 'exclamation_both_hands_a001',
	},
	{
		label: 'Exclamation left hands',
		value: 'exclamation_left_hands_a001',
	},
	{ label: 'Bowing', value: 'bowing_b001' },
	{ label: 'Hello', value: 'hello_a001' },
	{ label: 'Salute left', value: 'salute_left_b001' },
	{ label: 'Salute right', value: 'salute_right_b001' },
	{ label: 'Waving both hands', value: 'waving_both_hands_b001' },
	{ label: 'Waving left', value: 'waving_left_b001' },
	{ label: 'waving right', value: 'waving_right_b001' },
	{
		label: 'Negation both hands',
		value: 'negation_both_hands_a001',
	},
	{
		label: 'Negation left hand',
		value: 'negation_left_hand_a001',
	},
	{
		label: 'Negation right hand',
		value: 'negation_right_hand_b001',
	},
	{
		label: 'negation shake head',
		value: 'negation_shake_head_b001',
	},
	{
		label: 'Question both hand',
		value: 'question_both_hand_a001',
	},
	{
		label: 'Question right hand',
		value: 'question_right_hand_a001',
	},
	{
		label: 'Raise left hand so',
		value: 'raise_left_hand_so_a001',
	},
	{ label: 'Raise right hand', value: 'raise_right_hand_a002' },
	{
		label: 'Raise right hand so',
		value: 'raise_right_hand_so_a001',
	},
	{
		label: 'Spread both hands so',
		value: 'spread_both_hands_so_a001',
	},
	{ label: 'Raise left hand', value: 'raise_left_hand_a001' },
	{ label: 'Raise right hand', value: 'raise_right_hand_a001' },
	{
		label: 'Spread both hands',
		value: 'spread_both_hands_a001',
	},
];

gestureOptions.sort((a, b) => a.label.localeCompare(b.label));

export { gestureOptions };

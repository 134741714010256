import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useVoiceOverStore } from 'store';
import { randomId } from 'utils/functions/strings';

import {
	fetchAutocompleteSearch,
	handleClickOutsideSelect,
} from 'components/select/select.service';

import styles from './styles.module.css';

type SelectGroupType = {
	label: string;
	name?: string;
	value: unknown;
	options?: any[];
	disable?: boolean;
	keyName?: string;
	apiUrl?: string;
	apiParams?: object;
	optionsListParams?: object;
	showClear?: boolean;
	autoComplete?: boolean;
	initialSearch?: string;
	handleChange: (value: any, options: { name: string }) => void;
	handleClear?: () => void;
};

const SelectGroup = ({
	label,
	options,
	name,
	value,
	keyName,
	apiUrl,
	apiParams,
	optionsListParams,
	handleChange,
	handleClear,
	initialSearch,
	autoComplete,
	disable,
	showClear,
}: SelectGroupType) => {
	const [welcomeMessage, errorMessage] = useVoiceOverStore((state) => [
		state.welcomeMessage,
		state.errorMessage,
	]);
	const id = useMemo(() => randomId(), []);

	const [isListDisplayed, setListDisplayed] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [localOptions, setLocalOptions] = useState(options);
	const [search, setSearch] = useState('');

	const selectedOption = useMemo(() => {
		if (!localOptions || disable) return;

		return localOptions.filter((option) => {
			if (keyName) {
				return option.value[keyName]?.toString() === value?.toString();
			}

			return option.value?.toString() === value?.toString();
		})[0];
	}, [localOptions, value, keyName, disable]);

	// eslint-disable-next-line
	const debouncedSearch = useCallback(
		debounce(async (search: string) => {
			setIsLoading(true);
			const result = await fetchAutocompleteSearch(
				apiUrl ?? '',
				{ ...apiParams, search },
				optionsListParams,
			);
			setLocalOptions(result);
			setIsLoading(false);
		}, 500),
		[],
	);
	const isOptionDisabled = (option: string) => {
		let disabled = false;
		if (option === 'After the voice over') {
			disabled = true;
			if (welcomeMessage) {
				disabled = false;
			}
			if (errorMessage) {
				disabled = false;
			}
		}
		return disabled;
	};
	useEffect(() => {
		if (apiUrl) return;

		setLocalOptions(options);
	}, [options, apiUrl]);

	useEffect(() => {
		window.addEventListener('click', (click) =>
			handleClickOutsideSelect(id, click, setListDisplayed),
		);
		return () => {
			window.removeEventListener('click', (click) =>
				handleClickOutsideSelect(id, click, setListDisplayed),
			);
		};
	}, [id]);

	useEffect(() => {
		setListDisplayed(!disable && isListDisplayed);
		// eslint-disable-next-line
	}, [disable]);

	useEffect(() => {
		if (!apiUrl || disable) return;

		let params = { ...apiParams };

		if (initialSearch) params = { ...params, search: initialSearch };

		void (async () => {
			setIsLoading(true);
			const result = await fetchAutocompleteSearch(
				apiUrl,
				params,
				optionsListParams,
			);
			setLocalOptions(result);
			setIsLoading(false);
		})();
	}, [apiUrl, apiParams, optionsListParams, disable, initialSearch]);

	return (
		<div className="mb-3">
			{label ? (
				<label htmlFor={id} className="mb-2">
					{label}
				</label>
			) : (
				<></>
			)}
			<div id={id} className="position-relative d-flex gap-3">
				{autoComplete ? (
					<input
						className={`form-control border border-0 inherit-fw mb-1 rounded text-nowrap overflow-hidden px-2 position-relative no-glow ${
							disable ? 'disabled' : 'bg-white'
						} w-100 ${styles.selectedValue}`}
						placeholder={
							isLoading
								? 'Loading ...'
								: selectedOption?.label ?? `${label ?? 'Select'} ...`
						}
						onClick={() => setListDisplayed(!isListDisplayed && !isLoading)}
						value={search}
						onChange={(e) => {
							setListDisplayed(!!e.target.value);
							setSearch(e.target.value);
							void debouncedSearch(e.target.value);
						}}
					/>
				) : (
					<p
						className={`form-control border border-0 inherit-fw mb-1 rounded text-nowrap overflow-hidden px-2 position-relative d-flex align-items-center gap-2 ${
							disable ? 'disabled' : 'bg-white'
						} w-100 ${styles.selectedValue}`}
						onClick={() => setListDisplayed(!isListDisplayed && !isLoading)}
					>
						{selectedOption?.icon && (
							<span>
								<img src={selectedOption?.icon} />
							</span>
						)}
						{isLoading
							? 'Loading ...'
							: selectedOption?.label ?? `${label ?? 'Select'} ...`}
					</p>
				)}
				<ul
					className={`rounded p-0 ${styles.selectList} ${
						isListDisplayed && 'd-inline-block'
					}`}
				>
					{isLoading || !localOptions?.length ? (
						<li>{isLoading ? 'Loading ...' : 'No items found'}</li>
					) : (
						localOptions?.map((option, index) => (
							<li
								className={`d-flex align-items-center gap-2 ${
									isOptionDisabled(option.label) ? 'events-disabled' : ''
								} `}
								key={keyName ? option.value[keyName] : index}
								onClick={() => {
									autoComplete && setSearch(option.label);
									handleChange(option.value, { name: name ?? '' });
									setListDisplayed(false);
								}}
							>
								{option.icon && (
									<span>
										<img src={option.icon} />
									</span>
								)}
								{option.label}
							</li>
						))
					)}
				</ul>

				{showClear && (
					<div>
						<button className="btn-close" onClick={handleClear}></button>
					</div>
				)}
			</div>
		</div>
	);
};

export default SelectGroup;

const getIconPadding = (
	iconPath: string | undefined,
	iconPosition: string | undefined,
) => {
	if (!iconPath) return '';
	if (!iconPosition || iconPosition === 'start') return 'ps-4 pe-1';
	return 'pe-4';
};

const getIconPosition = (iconPosition: string | undefined) => {
	if (iconPosition === 'end') return 'end-0';
	return 'start-0';
};

export { getIconPadding, getIconPosition };

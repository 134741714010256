import { DEFAULT_UNIT } from 'shared/vars/constantLiterals';
import { removeLastMatchedStr } from 'utils/functions/strings';

const appendUnitToValue = (value: string) => {
	return `${value}${DEFAULT_UNIT}`;
};

const removeUnitFromValue = (value?: string) => {
	return removeLastMatchedStr(value ?? '', DEFAULT_UNIT);
};

export { appendUnitToValue, removeUnitFromValue };

import { type ScreenTransitionHandlerTypes } from 'store/use-screen-transition/Action';
import { type ScreenTransitionStateTypes } from 'store/use-screen-transition/State';
import { create } from 'zustand';

const initialState: ScreenTransitionStateTypes = {
	hasScreenTransition: false,
	trigger: '',
	transitionTo: '',
};

const useScreenTransitionStore = create<
	ScreenTransitionStateTypes & ScreenTransitionHandlerTypes
>((set) => ({
	...initialState,
	updateHasScreenTransition: (hasScreenTransition) =>
		set(() => ({ ...initialState, hasScreenTransition })),
	updateTrigger: (trigger) => set(() => ({ trigger })),
	updateTransitionTo: (transitionTo) => set(() => ({ transitionTo })),
}));

export { type ScreenTransitionStateTypes, useScreenTransitionStore };

import { X } from 'react-bootstrap-icons';

import { handleBtnClick } from './tags.service';

import styles from './tags.module.css';

const Tags = ({
	tags,
	handleClick,
}: {
	tags: string[];
	handleClick?: Function;
}) => {
	return (
		<div className="d-flex flex-wrap my-3">
			{tags.map((tag) => {
				return (
					<button
						className={`d-flex align-items-center flex-nowrap p-1 m-1 ${styles.tag}`}
						key={tag}
						data-value={tag}
						onClick={(e) => handleBtnClick(e, handleClick)}
					>
						{tag}

						<X
							data-value={tag}
							onClick={(e) => handleBtnClick(e, handleClick)}
							className={`ms-1  ${styles.icon}`}
							size={16}
						/>
					</button>
				);
			})}
		</div>
	);
};

export default Tags;

import { useMemo } from 'react';
import { Component } from 'editor/grapes-js/Component';
import { handleCheckboxChange } from 'modules/attribute-settings/utils/attributes.service';
import { handleVideoImagePreviewChange } from 'modules/attribute-settings/video/video.service';
import { useObjectSettingsStore } from 'store';
import { isYoutubeLink } from 'utils/functions/urls';

import CheckboxGroup from 'components/checkbox/Checkbox';
import ControlledInputGroup from 'components/input/ControlledInput';
import Sector from 'components/sector/Sector';

const VideoTrait = () => {
	const [object, properties] = useObjectSettingsStore((state) => [
		state.object,
		state.componentAttributes,
	]);
	Component.updateInstance(object!);

	const videoSrc = object?.attributes.attributes
		? object.attributes.attributes['video-src']
		: '';

	const isYoutubeVideo = useMemo(() => isYoutubeLink(videoSrc), [videoSrc]);

	return (
		<Sector title="Video">
			<CheckboxGroup
				id="video-loop"
				label="Video Loop"
				name="video-loop"
				value={properties['video-loop'] ?? false}
				handleChange={handleCheckboxChange}
			/>

			<CheckboxGroup
				id="auto-play"
				label="Auto Play"
				name="auto-play"
				value={properties['auto-play'] ?? false}
				handleChange={handleCheckboxChange}
			/>

			{!isYoutubeVideo && (
				<ControlledInputGroup
					id="image-preview"
					label="Image preview"
					placeholder="http://path/to/the/image.jpg"
					name="image-preview"
					value={String(properties['image-preview'] ?? '')}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						handleVideoImagePreviewChange(e.target.value)
					}
				/>
			)}
		</Sector>
	);
};

export default VideoTrait;

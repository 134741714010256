import { raiseError } from 'utils/message-senders/raiseError';

type ApplicationVariableType = Record<string, string>;

interface GeneralSettingHandlerTypes {
	updateScreenName: (screenName: string) => void;
	updateLanguage: (language: string) => void;
	updateRobotType: (robotType: string) => void;
	updateNextScreens: (nextScreens: OptionType[]) => void;
	initializeApplicationVariables: (
		applicationVariables: ApplicationVariableType,
	) => void;
	updateApplicationVariable: (
		applicationVariable: ApplicationVariableType,
	) => void;
	removeApplicationVariable: (componentID: string) => void;
	updateContacts: (contacts: OptionType[]) => void;
	updateLocations: (locations: OptionType[]) => void;
	updateOrganizationId: (orgId: string) => void;
	updateAssignedMapId: (assignedMapId: string) => void;
	updateDefaultCountryCode: (countryCode: string) => void;
}

const handleUpdateApplicationVariables = (
	oldState: ApplicationVariableType,
	newVariable: ApplicationVariableType,
) => {
	const newVariableKey = Object.keys(newVariable ?? {})[0];
	const newVariableValue = Object.values(newVariable ?? {})[0];

	const oldStateValues = Object.values(oldState ?? {});
	if (
		oldState[newVariableKey] !== newVariableValue &&
		oldStateValues.includes(newVariableValue)
	) {
		raiseError([`${newVariableValue} already exists`]);
		return oldState;
	} else if (newVariableValue) {
		return { ...oldState, [newVariableKey]: newVariableValue };
	}
};

const handleRemoveApplicationVariable = (
	oldState: ApplicationVariableType,
	componentID: string,
) => {
	if (!oldState[componentID]) return oldState;
	const newState = { ...oldState };
	// eslint-disable-next-line
	delete newState[componentID];
	return newState;
};

export {
	type GeneralSettingHandlerTypes,
	handleRemoveApplicationVariable,
	handleUpdateApplicationVariables,
};

const handleBtnClick = (
	event:
		| React.MouseEvent<SVGElement, MouseEvent>
		| React.MouseEvent<HTMLButtonElement, MouseEvent>,
	handleClick?: Function,
) => {
	if (handleClick) handleClick(event.currentTarget.dataset.value);
};

export { handleBtnClick };

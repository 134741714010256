import type { Component } from 'grapesjs';

const nestedComponentsHandler = {
	listenerType: 'component:deselected' as const,
	handlerCallBack: (model: Component) => {
		if (model.attributes.type !== 'wrapper' && model.attributes.type !== 'text')
			removeNestedComponents(model);
	},
};

export { nestedComponentsHandler };

const removeNestedComponents = (model: Component) => {
	const firstEl: HTMLElement = (model.getView() as any).$el[0];
	firstEl.innerHTML = firstEl.innerText;
};

import {
	type AssignedLocationTypes,
	type GeneralSettingStateTypes,
	type KeywordStateTypes,
	type MaskDetectionStateTypes,
	type ScreenTransitionStateTypes,
	type SensoryInteractionStateTypes,
	type VoiceOverStateTypes,
} from 'store';
import { Screen } from 'utils/validation/Screen';

const createScreen = (
	generalSettings: GeneralSettingStateTypes,
	keywords: KeywordStateTypes,
	maskDetection: MaskDetectionStateTypes,
	assignedLocation: AssignedLocationTypes,
	screenTransition: ScreenTransitionStateTypes,
	sensoryInteractions: SensoryInteractionStateTypes,
	voiceOver: VoiceOverStateTypes,
	editor: { components: any[]; styles: any[] },
) => {
	const screen = new Screen()
		.generalSettings(generalSettings)
		.keywords(keywords)
		.maskDetection(maskDetection)
		.assignLocation(assignedLocation)
		.screenTransition(screenTransition)
		.sensoryInteractions(sensoryInteractions)
		.voiceOver(voiceOver)
		.editor(editor)
		.body();
	return screen;
};

export { createScreen };

import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { useConfigurationStore } from 'store';

const axiosInstance = axios.create({
	withCredentials: true,
});
axiosInstance.interceptors.request.use(function (config) {
	config.baseURL = useConfigurationStore.getState().URL;

	config.headers.Authorization = useConfigurationStore.getState().token;

	return config;
});

const api = setupCache(axiosInstance);

export default api;

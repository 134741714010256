import type { AddComponentTypeOptions, Editor } from 'grapesjs';

const addQRCodeComponentPlugin = (editor: Editor) => {
	const componentsOptions: AddComponentTypeOptions = {
		isComponent: (el: HTMLElement) => el.tagName === 'QRCODE',
		model: {
			defaults: {
				tagName: 'QRCode',
				resizable: true,
				editable: true,
				attributes,
				traits: [],
			},
		},
		view: {
			init() {
				this.model.setStyle({ ...style, ...this.model.getStyle() });
			},
		},
	};
	editor.DomComponents.addType('QR-code', componentsOptions);
};

export { addQRCodeComponentPlugin };

const style = {
	height: '250px',
	width: '250px',
	border: 'none',
	background: '#F4F4F7',
	'border-color': '#ffffff',
	'border-top-left-radius': '10px',
	'border-top-right-radius': '10px',
	'border-bottom-left-radius': '10px',
	'border-bottom-right-radius': '10px',
	'background-image': ' url(/assets/editor/qr-code.svg)',
	'background-size': 'cover',
	'background-position': 'center',
};

const attributes = {
	type: 'QR-code',
	backgroundImage: false,
};

import { textIcon } from 'editor/common/icons/textIcon';

const textBlock = {
	id: 'text',
	options: {
		category: 'Components',
		label: 'Text',
		media: textIcon,
		content: {
			type: 'text',
		},
	},
};

export { textBlock };

import { type FC } from 'react';
import { useObjectSettingsStore } from 'store';

const selectedComponentHandler = {
	listenerType: 'component:selected' as const,
	handlerCallBack:
		(componentSettings: ComponentSettings) => (model: Component) => {
			const componentType = (model.attributes.type ?? '') as ComponentTypes;
			const component = componentSettings[componentType];
			if (component) {
				updateSelectedObject(model);
				updateComponentStyles(model);
				updateComponentAttributes(model);
				updateComponentSectorsInObjectSettings(component);
			}
		},
};

export { selectedComponentHandler };

const updateSelectedObject = (model: Component) => {
	useObjectSettingsStore.getState().updateObject(model);
};

const updateComponentStyles = (model: Component) => {
	useObjectSettingsStore.getState().updateStyles(model.getStyle());
};

const updateComponentAttributes = (model: Component) => {
	useObjectSettingsStore.getState().updateAttributes(model.getAttributes());
};

const updateComponentSectorsInObjectSettings = (component: {
	styles: FC[];
	properties: FC[];
}) => {
	useObjectSettingsStore
		.getState()
		.updateSectors([...component.styles, ...component.properties]);
};

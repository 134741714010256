import { useEffect } from 'react';
import Navigation from 'interfaces/navigation/Navigation';
import Tab from 'interfaces/tab/Tab';
import { RobotConfigFactory } from 'robots/RobotConfigFactory';
import { getScreenSettingsComponents } from 'shared/vars/screenSettings';
import { useGeneralSettingStore, useObjectSettingsStore } from 'store';

import styles from './standard-layout.module.css';

const StandardLayout = () => {
	const [robotType] = useGeneralSettingStore((state) => [state.robotType]);
	const [sectors] = useObjectSettingsStore((state) => [state.sectors]);

	useEffect(() => {
		if (!window.editor) {
			const editor = RobotConfigFactory.getRobotConfig(robotType);
			window.editor = editor;
		}
		return () => {
			window.editor.destroyEditor();
		};
	}, [robotType]);
	return (
		<div className="d-flex">
			<aside className={`shadow-sm ${styles.leftPanel}`}>
				<Tab
					tabInfo={[
						{ id: 'blocks-container', title: 'Elements', active: true },
						{ id: 'layers-container', title: 'Layer' },
					]}
				/>
			</aside>
			<main className="center flex-grow-1 d-flex flex-column overflow-hidden">
				<nav className={`shadow-sm border-bottom ${styles.topPanel}`}>
					<Navigation />
				</nav>
				<div className={`${styles.editorSpace}`}>
					<div id="grapes-js" />
				</div>
			</main>
			<aside className={`shadow-sm ${styles.rightPanel}`}>
				<Tab
					tabInfo={[
						{
							id: 'object-setting',
							title: 'Object Settings',
							active: true,
							dynamicComponents: sectors,
						},
						{
							id: 'screen-settings',
							title: 'Screen settings',
							dynamicComponents: getScreenSettingsComponents(robotType),
						},
					]}
				/>
			</aside>
		</div>
	);
};

export default StandardLayout;

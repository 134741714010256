import {
	handleAddVoiceInput,
	handleRemoveVoiceInput,
	handleUpdateInteraction,
} from 'modules/attribute-settings/outcoming-interaction/outcomeInteraction.service';
import { ARROW_ICON_PATH } from 'shared/vars/path';
import { useGeneralSettingStore } from 'store';

import InputGroup from 'components/input/Input';
import SelectGroup from 'components/select/Select';
import Tags from 'components/tags/Tags';

type Props = {
	interaction: ScreenTransitionInteractionType;
	index: number;
};

const ScreenTransition = ({ interaction, index }: Props) => {
	const [nextScreens] = useGeneralSettingStore((state) => [state.nextScreens]);
	return (
		<>
			<SelectGroup
				label="Select Transit to"
				value={interaction['transition-screen']}
				options={nextScreens}
				handleChange={(value: string) => {
					handleUpdateInteraction(
						{
							...interaction,
							'transition-screen': value.toString(),
						},
						index,
					);
				}}
			/>
			<InputGroup
				id="voice-input"
				label="Select Voice input"
				placeholder="Move to home screen"
				listenTo={[
					{
						code: 'Enter',
						handleCallBack: (voice: string) =>
							handleAddVoiceInput(voice, interaction, index),
						resetValue: true,
					},
				]}
				iconPath={`${ARROW_ICON_PATH}/down-left-arrow.svg`}
				iconPosition="end"
			/>
			<Tags
				tags={interaction['voice-input']}
				handleClick={(voice: string) =>
					handleRemoveVoiceInput(voice, interaction, index)
				}
			/>
		</>
	);
};

export default ScreenTransition;

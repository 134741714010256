import { useEffect, useState } from 'react';
import { handleWindowMessageCallback } from 'app.service';
import { cleanupEventListeners } from 'globals/events';
import StandardLayout from 'layouts/standard/StandardLayout';

import Autocomplete from 'components/autocomplete/Autocomplete';
import Spinner from 'components/spinner/Spinner';
import Tooltip from 'components/tooltip/Tooltip';

function App() {
	const [unlockApp, setUnlockApp] = useState({
		message: false,
	});

	useEffect(() => {
		return () => {
			cleanupEventListeners();
		};
	}, []);

	window.addEventListener('message', (message) =>
		handleWindowMessageCallback(message, setUnlockApp),
	);

	return unlockApp.message ? (
		<>
			<StandardLayout />
			<div className="utilities">
				<Tooltip />
				<Autocomplete />
			</div>
		</>
	) : (
		<Spinner />
	);
}

export default App;

import { type GeneralSettingStateTypes } from 'store';

class ScreenValidator {
	private readonly _errorStack: string[];

	constructor() {
		this._errorStack = [];
	}

	validateGeneralSettings(generalSettings: Partial<GeneralSettingStateTypes>) {
		if (!(generalSettings.screenName ?? '').trim())
			this._errorStack.push("Screen name can't be empty");
		if (!(generalSettings.language ?? '').trim())
			this._errorStack.push("language can't be empty");
	}

	validateEditor(
		components: any[],
		styles: Array<{ style?: { 'background-image': string } }>,
	) {
		if (
			!(styles[0].style ?? { 'background-image': '' })['background-image'] &&
			!components.length
		)
			this._errorStack.push("Screen components can't be empty");
	}

	getErrorStack() {
		return this._errorStack;
	}
}

export { ScreenValidator };

import { useGlobalEventsStore } from 'store';

const handleFullScreenChange = (event: Event) => {
	event.stopPropagation();

	const isFullScreenOn = useGlobalEventsStore.getState().isFullScreenOn;

	const updateToggleFullScreen =
		useGlobalEventsStore.getState().toggleFullScreen;

	updateToggleFullScreen(!isFullScreenOn);
};

export { handleFullScreenChange };

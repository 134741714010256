import {
	type AssignedLocationTypes,
	type KeywordStateTypes,
	type MaskDetectionStateTypes,
	type ScreenTransitionStateTypes,
	type SensoryInteractionStateTypes,
	type VoiceOverStateTypes,
} from 'store';
import { type GeneralSettingStateTypes } from 'store/use-general-settings';
import { ComponentValidator } from 'utils/validators/ComponentValidator';

import { ScreenValidator } from '../validators/ScreenValidator';

class Screen {
	private _body: object;
	private readonly _screenValidator = new ScreenValidator();
	private readonly _componentValidator = new ComponentValidator();

	generalSettings(generalSettings: GeneralSettingStateTypes) {
		this._screenValidator.validateGeneralSettings(generalSettings);
		this._body = {
			...this._body,
			name: generalSettings.screenName,
			language: generalSettings.language,
			applicationVariables: generalSettings.applicationVariables,
		};
		return this;
	}

	keywords(keywords: KeywordStateTypes) {
		this._body = { ...this._body, ...keywords };
		return this;
	}

	maskDetection(maskDetection: MaskDetectionStateTypes) {
		this._body = {
			...this._body,
			isMask: maskDetection.hasMaskDetection,
			maskMessage: maskDetection.maskDetectionMessage,
		};
		return this;
	}

	assignLocation(assignedLocation: AssignedLocationTypes) {
		this._body = {
			...this._body,
			assignedLocation: assignedLocation.assignedLocation,
			assignedLocationErrorMessage:
				assignedLocation.assignedLocationErrorMessage,
		};
		return this;
	}

	screenTransition(screenTransition: ScreenTransitionStateTypes) {
		this._body = {
			...this._body,
			isTransition: screenTransition.hasScreenTransition,
			duration: screenTransition.trigger,
			transition: screenTransition.transitionTo,
		};
		return this;
	}

	sensoryInteractions(sensoryInteractions: SensoryInteractionStateTypes) {
		this._body = {
			...this._body,
			...sensoryInteractions,
		};
		return this;
	}

	voiceOver(voiceOver: VoiceOverStateTypes) {
		this._body = {
			...this._body,
			output: voiceOver.welcomeMessage,
			error: voiceOver.errorMessage,
			gesture: voiceOver.gesture,
		};
		return this;
	}

	editor(editor: { components: any[]; styles: any[] }) {
		this._screenValidator.validateEditor(editor.components, editor.styles);
		this.validateComponent(editor.components);
		this._body = {
			...this._body,
			components: JSON.stringify(editor.components),
			styles: JSON.stringify(editor.styles),
		};
		return this;
	}

	body() {
		return {
			body: this._body,
			errorStack: [
				...this._screenValidator.getErrorStack(),
				...this._componentValidator.getErrorStack(),
			],
		};
	}

	private validateComponent(components: any[]) {
		this._componentValidator.validate(components);
	}
}

export { Screen };

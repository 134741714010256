import styles from './sector.module.css';

const Sector = ({
	title,
	children,
}: {
	title: string;
	children: React.ReactNode;
}) => {
	return (
		<div
			className={`accordion accordion-flush ${styles.sectorContainer}`}
			id={title.replaceAll(' ', '-')}
		>
			<div className={`accordion-item ${styles.sectorItem}`}>
				<h2 className="accordion-header">
					<button
						className={`accordion-button no-glow ${styles.sectorTitle}`}
						type="button"
						data-bs-toggle="collapse"
						data-bs-target={`#${title.replaceAll(' ', '-')}-collapse`}
					>
						{title}
					</button>
				</h2>
				<div
					id={`${title.replaceAll(' ', '-')}-collapse`}
					className="accordion-collapse collapse show"
					data-bs-parent={`#${title.replaceAll(' ', '-')}`}
				>
					<div className={styles.sectorProperties}>{children}</div>
				</div>
			</div>
		</div>
	);
};

export default Sector;

import { imageIcon } from 'editor/common/icons/imageIcon';

const imageBlock = {
	id: 'image',
	options: {
		category: 'Components',
		label: 'Image',
		media: imageIcon,
		content: {
			type: 'image',
		},
	},
};

export { imageBlock };

import { qrCodeIcon } from 'editor/common/icons/qrCodeIcon';

const QrCodeBlock = {
	id: 'QR-Code',
	options: {
		category: 'Components',
		label: 'QR Code',
		media: qrCodeIcon,
		content: {
			type: 'QR-code',
		},
	},
};

export { QrCodeBlock };

import { buttonIcon } from 'editor/common/icons/buttonIcon';

const buttonBlock = {
	id: 'button',
	options: {
		category: 'Components',
		label: 'Button',
		media: buttonIcon,
		content: {
			type: 'button',
		},
	},
};

export { buttonBlock };

import { useConfigurationStore } from 'store';

const apiUrl = (resource: string) =>
	`${useConfigurationStore.getState().URL}/${resource}`;

const asset = (asset: string) =>
	`${useConfigurationStore.getState().URL}/assets/${asset}`;

const isYoutubeLink = (link: string) =>
	!!link?.match(/^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm);

export { apiUrl, asset, isYoutubeLink };

const qrCodeIcon = `<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5 5H9V9H5V5Z" fill="#827F91"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2H2L2 12H12V2ZM2 0C0.895431 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.895431 13.1046 0 12 0H2Z" fill="#827F91"/>
<path d="M5 23H9V27H5V23Z" fill="#DDDDE3"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 20H2L2 30H12V20ZM2 18C0.895431 18 0 18.8954 0 20V30C0 31.1046 0.89543 32 2 32H12C13.1046 32 14 31.1046 14 30V20C14 18.8954 13.1046 18 12 18H2Z" fill="#DDDDE3"/>
<path d="M23 5H27V9H23V5Z" fill="#827F91"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30 2H20L20 12H30V2ZM20 0C18.8954 0 18 0.89543 18 2V12C18 13.1046 18.8954 14 20 14H30C31.1046 14 32 13.1046 32 12V2C32 0.895431 31.1046 0 30 0H20Z" fill="#827F91"/>
<path d="M23 23H27V27H23V23Z" fill="#827F91"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30 20H20L20 30H30V20ZM20 18C18.8954 18 18 18.8954 18 20V30C18 31.1046 18.8954 32 20 32H30C31.1046 32 32 31.1046 32 30V20C32 18.8954 31.1046 18 30 18H20Z" fill="#827F91"/>
</svg>
`;

export { qrCodeIcon };

import { useEffect } from 'react';
import { InfoCircle } from 'react-bootstrap-icons';

import { handleEditableParagraph } from 'components/editable-paragraph/editableParagraph.service';

import styles from './styles.module.css';

type EditableParagraphGroupType = {
	id: string;
	label: string;
	value: string;
	placeholder: string;
	customStyling?: string;
	disable?: boolean;
	tooltipContent?: string;
	autocompleteOptions?: string[];
	handleChange: (change: string) => void;
};

const EditableParagraphGroup = ({
	id,
	label,
	value,
	placeholder,
	handleChange,
	customStyling,
	disable,
	tooltipContent,
	autocompleteOptions,
}: EditableParagraphGroupType) => {
	useEffect(() => {
		document.getElementById(id)!.innerHTML = value;
		// eslint-disable-next-line
	}, []);
	return (
		<div className="mb-3">
			{label && (
				<label htmlFor={id} className="my-2">
					{label}
					{tooltipContent && (
						<span className="custom-tooltip mx-1" data-content={tooltipContent}>
							<InfoCircle />
						</span>
					)}
				</label>
			)}
			<p
				id={id}
				className={`${styles.fakeTextarea} ${
					disable && 'disabled'
				} form-control no-glow border border-0 inherit-fw no-resize ${customStyling}`}
				contentEditable
				placeholder={placeholder}
				onInput={(event) =>
					handleEditableParagraph(event, handleChange, autocompleteOptions)
				}
			></p>
		</div>
	);
};

export default EditableParagraphGroup;

import { TOOLTIP_CONTENT } from 'shared/content/tooltip';
import { gestureOptions } from 'shared/options/gestures';
import { SUPPORTED_ROBOTS } from 'shared/vars/robots';
import { useGeneralSettingStore, useVoiceOverStore } from 'store';

import EditableParagraphGroup from 'components/editable-paragraph/EditableParagraph';
import Sector from 'components/sector/Sector';
import SelectGroup from 'components/select/Select';

const VoiceOver = () => {
	const [robotType, applicationVariables] = useGeneralSettingStore((state) => [
		state.robotType,
		state.applicationVariables,
	]);
	const [
		welcomeMessage,
		errorMessage,
		gesture,
		updateWelcomeMessage,
		updateErrorMessage,
		updateGesture,
	] = useVoiceOverStore((state) => [
		state.welcomeMessage,
		state.errorMessage,
		state.gesture,
		state.updateWelcomeMessage,
		state.updateErrorMessage,
		state.updateGesture,
	]);

	return (
		<Sector title="Voice over">
			<EditableParagraphGroup
				id="welcome-message"
				label="Voice Welcome message"
				placeholder="Enter your welcome message ..."
				value={welcomeMessage}
				handleChange={updateWelcomeMessage}
				tooltipContent={TOOLTIP_CONTENT.WELCOME_MESSAGE}
				autocompleteOptions={Object.values(applicationVariables)}
			/>
			{robotType === SUPPORTED_ROBOTS.PEPPER && (
				<SelectGroup
					label="Select Pepper's gesture"
					options={gestureOptions}
					value={gesture}
					handleChange={updateGesture}
				/>
			)}
			<EditableParagraphGroup
				id="error-message"
				label="Error message"
				placeholder="Enter your error message ..."
				value={errorMessage}
				handleChange={updateErrorMessage}
				tooltipContent={TOOLTIP_CONTENT.ERROR_MESSAGE}
				autocompleteOptions={Object.values(applicationVariables)}
			/>
		</Sector>
	);
};

export default VoiceOver;

import type { Component as GrapesJsComponent } from 'grapesjs';

// eslint-disable-next-line
class Component {
	private static _component: GrapesJsComponent;

	private constructor() {}

	static updateInstance(component: GrapesJsComponent) {
		if (!this._component || this._component.ccid !== component.ccid)
			this._component = component;

		return Component;
	}

	static addStyle(propertyName: StyleProperties, propertyValue: unknown) {
		this._component.addStyle({ [propertyName]: propertyValue });
	}

	static addAttribute(
		attributeName: ComponentAttributes,
		attributeValue: unknown,
	) {
		this._component.addAttributes({ [attributeName]: attributeValue });
	}
}

export { Component };

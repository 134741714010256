import { handleUpdateInteraction } from 'modules/attribute-settings/outcoming-interaction/outcomeInteraction.service';

import ControlledInputGroup from 'components/input/ControlledInput';

type Props = {
	interaction: ThirdPartyAPKInteractionType;
	index: number;
};

const ApkDeepLink = ({ interaction, index }: Props) => {
	return (
		<ControlledInputGroup
			id="apk-deeplink"
			label="Select APK deeplink"
			placeholder="myapp://path/to/page?pageid=1"
			name="apk-deeplink"
			value={String(interaction['apk-deeplink'] ?? '')}
			onChange={(e) => {
				handleUpdateInteraction(
					{
						...interaction,
						'apk-deeplink': e.target.value,
					},
					index,
				);
			}}
		/>
	);
};

export default ApkDeepLink;

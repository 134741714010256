const getLowercaseStr = (str: string | number) => str.toString().toLowerCase();

const isStrEndWith = (str: string, endWithStr: string) =>
	!str || !endWithStr ? false : str.endsWith(endWithStr);

const removeLastMatchedStr = (str: string, removedMatchedStr: string) => {
	try {
		const lastIndex = str.lastIndexOf(removedMatchedStr);
		return str.substring(0, lastIndex);
	} catch (e) {
		return str;
	}
};

const randomId = () => Math.floor(Math.random() * 999999).toString();

export { getLowercaseStr, isStrEndWith, randomId, removeLastMatchedStr };

import { updateModalContext } from 'editor/grapes-js/utils/custom-modal';
import type { AddComponentTypeOptions, Component, Editor } from 'grapesjs';

const addImageComponentPlugin = (editor: Editor) => {
	const componentsOptions: AddComponentTypeOptions = {
		isComponent: (el: HTMLElement) => el.tagName === 'Image',
		model: {
			defaults: {
				tagName: 'img',
				resizable: true,
				editable: true,
				attributes,
				traits: [],
			},
		},
		view: {
			init({ model, editor }: { model: Component; editor: Editor }) {
				this.handleOpenModal = this.handleOpenModal.bind(this, editor);
				model.getEl()?.addEventListener('dblclick', this.handleOpenModal);

				model.setStyle({ ...style, ...this.model.getStyle() });
			},
			removed(removedEl: any) {
				removedEl.el.removeEventListener('dblclick', this.handleOpenModal);
			},
			handleOpenModal(editor: Editor) {
				handleUploadNewVideo(editor);
			},
		},
	};
	editor.DomComponents.addType('image', componentsOptions);
};

export { addImageComponentPlugin };

const style = {
	height: '250px',
	width: '250px',
	color: '#FFFFFF',
	border: 'none',
	'border-color': '#ffffff',
	'border-top-left-radius': '10px',
	'border-top-right-radius': '10px',
	'border-bottom-left-radius': '10px',
	'border-bottom-right-radius': '10px',
	'background-color': '#7f6df2',
};

const attributes = {
	type: 'image',
	src: '/assets/editor/broken-image.svg',
	backgroundImage: false,
};

const handleUploadNewVideo = (editor: Editor) => {
	editor.AssetManager.open({
		types: ['image'],
		select(asset, complete) {
			const selected = editor.getSelected();
			if (selected?.is('image')) {
				selected.addAttributes({ src: asset.getSrc() });
				complete && editor.AssetManager.close();
			}
		},
	});
	updateModalContext('Images', 'http://image-link.com', 'Add image');
};

const dragComponentsHandler = {
	listenerType: 'component:drag' as const,
	handlerCallBack: (e: any) => {
		window.editor.restrictPosition(e);
	},
};
const dragEndComponentsHandler = {
	listenerType: 'component:drag:end' as const,
	handlerCallBack: (e: any) => {
		window.editor.restrictPosition(e);
	},
};

const dragStopBlockHandler = {
	listenerType: 'block:drag:stop' as const,
	handlerCallBack: (e: any) => {
		window.editor.restrictPosition(e);
	},
};

export {
	dragComponentsHandler,
	dragEndComponentsHandler,
	dragStopBlockHandler,
};

const inputIcon = `<svg width="52" height="26" viewBox="0 0 52 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="path-1-inside-1_12698_9282" fill="white">
<rect width="52" height="26" rx="2"/>
</mask>
<rect width="52" height="26" rx="2" stroke="#DDDDE3" stroke-width="8" mask="url(#path-1-inside-1_12698_9282)"/>
<path d="M8 7H10.5M10.5 7H13M10.5 7V19M10.5 19H8M10.5 19H13" stroke="#7F6DF2" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

export { inputIcon };

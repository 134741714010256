type CheckBoxGroupType = {
	id: string;
	label: string;
	name?: string;
	value: any;
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const CheckboxGroup = ({
	id,
	label,
	name,
	value,
	handleChange,
}: CheckBoxGroupType) => {
	return (
		<div className="form-check mb-3">
			<input
				className="form-check-input no-glow"
				type="checkbox"
				id={id}
				name={name}
				checked={value}
				onChange={handleChange}
			/>
			<label className="form-check-label" htmlFor={id}>
				{label}
			</label>
		</div>
	);
};

export default CheckboxGroup;

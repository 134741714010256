import { type MaskDetectionHandlerTypes } from 'store/use-mask-detection/Action';
import { type MaskDetectionStateTypes } from 'store/use-mask-detection/State';
import { create } from 'zustand';

const initialState: MaskDetectionStateTypes = {
	hasMaskDetection: false,
	maskDetectionMessage: '',
};

const useMaskDetectionStore = create<
	MaskDetectionStateTypes & MaskDetectionHandlerTypes
>((set) => ({
	...initialState,

	updateHasMaskDetection: (hasMaskDetection) =>
		set(() => ({ hasMaskDetection })),
	updateMaskDetectionMessage: (maskDetectionMessage) =>
		set(() => ({ maskDetectionMessage })),
}));

export { type MaskDetectionStateTypes, useMaskDetectionStore };

import { SUPPORTED_ROBOTS } from 'shared/vars/robots';

import { PepperConfig } from './config/pepper.config';
import { TemiConfig } from './config/temi.config';

// eslint-disable-next-line
class RobotConfigFactory {
	private constructor() {}
	static getRobotConfig(robotName: SUPPORTED_ROBOTS) {
		switch (robotName) {
			case SUPPORTED_ROBOTS.PEPPER:
				return this.createPepperRobot();
			case SUPPORTED_ROBOTS.TEMI:
				return this.createTemiRobot();
			default:
				return this.createPepperRobot();
		}
	}

	private static createPepperRobot() {
		return new PepperConfig().getConfig();
	}

	private static createTemiRobot() {
		return new TemiConfig().getConfig();
	}
}

export { RobotConfigFactory };

class ComponentValidator {
	private readonly _errorStack: Set<string>;

	constructor() {
		this._errorStack = new Set<string>();
	}

	validate(components: any[]) {
		components.forEach((component) => {
			if (component.type === 'input') {
				this.validateInputNameComponent(component);
				this.validateVariableNameComponent(component);
			} else if (component.type === 'QR-code') {
				this.validateQRCodeComponent(component);
			}
		});

		this.validateInteractionsIds(components);
	}

	validateQRCodeComponent(component: any) {
		try {
			if (!component.attributes['QRCode-URL'])
				throw new Error("QR code link can't be empty");
		} catch (e: any) {
			this._errorStack.add(e.message);
		}
	}

	validateInputNameComponent(component: any) {
		try {
			if (!component.attributes['input-name'])
				throw new Error("Input name can't be empty");
			else if (component.attributes['input-name'].length > 20)
				throw new Error("Input name can't be more than 20 char");
		} catch (e: any) {
			this._errorStack.add(e.message);
		}
	}

	validateVariableNameComponent(component: any) {
		try {
			if (component.attributes.variable?.length > 20)
				throw new Error("Variable name can't be larger than 20 character");
		} catch (e: any) {
			this._errorStack.add(e.message);
		}
	}

	validateInteractionsIds(components: any[]) {
		const ids: string[] = [];

		try {
			components.forEach((component) => {
				if (!component.attributes.interactions) return;

				component.attributes.interactions.forEach(
					(interaction: InteractionType) => {
						if (interaction.id == null || interaction.id === '') return;

						ids.forEach((id) => {
							if (interaction.id === id)
								throw new Error(`Duplicate Interaction id [${id}]`);
						});

						ids.push(interaction.id);
					},
				);
			});
		} catch (e: any) {
			this._errorStack.add(e.message);
		}
	}

	getErrorStack() {
		return Array.from(this._errorStack);
	}
}

export { ComponentValidator };

import {
	useAssignLocationStore,
	useGeneralSettingStore,
	useGlobalEventsStore,
	useKeywordStore,
	useMaskDetectionStore,
	useScreenTransitionStore,
	useSensoryInteractionStore,
	useVoiceOverStore,
} from 'store';
import { createScreen } from 'utils/creators/screenCreator';
import { fireBackButton } from 'utils/message-senders/backButton';
import { raiseError } from 'utils/message-senders/raiseError';
import { saveScreen } from 'utils/message-senders/saveScreen';

const handleSaveScreen = async () => {
	const latestApplicationState = getLatestApplicationState();
	const screen = createScreen(
		latestApplicationState.generalSettings,
		latestApplicationState.keywords,
		latestApplicationState.maskDetection,
		latestApplicationState.assignedLocation,
		latestApplicationState.screenTransition,
		latestApplicationState.sensoryInteractions,
		latestApplicationState.voiceOver,
		latestApplicationState.editor as { components: any[]; styles: any[] },
	);

	if (screen.errorStack.length) {
		raiseError(screen.errorStack);
	} else {
		saveScreen(screen.body);
	}
};

const handleBackButton = (
	e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
) => {
	e.stopPropagation();
	fireBackButton();
};

const handleFullScreenToggle = () => {
	useGlobalEventsStore.getState().isFullScreenOn
		? handleFullScreenToggleOff()
		: handleFullScreenToggleOn();
};

export { handleBackButton, handleFullScreenToggle, handleSaveScreen };

const handleFullScreenToggleOn = () => {
	void document.documentElement.requestFullscreen();
};

const handleFullScreenToggleOff = () => {
	void document.exitFullscreen();
};

const getLatestApplicationState = () => {
	const {
		screenName,
		language,
		robotType,
		nextScreens,
		orgId,
		applicationVariables,
	} = useGeneralSettingStore.getState();

	const { assignedLocation, assignedLocationErrorMessage } =
		useAssignLocationStore.getState();

	const keywords = useKeywordStore.getState().keywords;

	const { hasMaskDetection, maskDetectionMessage } =
		useMaskDetectionStore.getState();

	const { hasScreenTransition, trigger, transitionTo } =
		useScreenTransitionStore.getState();

	const { welcomeMessage, errorMessage, gesture } =
		useVoiceOverStore.getState();

	const {
		headSensorAction,
		headSensorValue,
		handSensorAction,
		handSensorValue,
	} = useSensoryInteractionStore.getState();

	return {
		generalSettings: {
			screenName,
			language,
			robotType,
			nextScreens,
			orgId,
			applicationVariables,
		},
		keywords: { keywords },
		maskDetection: { hasMaskDetection, maskDetectionMessage },
		assignedLocation: { assignedLocation, assignedLocationErrorMessage },
		screenTransition: { hasScreenTransition, trigger, transitionTo },
		voiceOver: { welcomeMessage, errorMessage, gesture },
		sensoryInteractions: {
			headSensorAction,
			headSensorValue,
			handSensorAction,
			handSensorValue,
		},
		editor: {
			styles: window.editor.exportStyles(),
			components: window.editor.exportComponents(),
		},
	};
};

const borderStyles = [
	{
		value: 'solid',
		label: 'Solid',
	},
	{
		value: 'dashed',
		label: 'Dashed',
	},
	{
		value: 'unset',
		label: 'Unset',
	},
];

export { borderStyles };

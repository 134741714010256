import { useConfigurationStore } from 'store';

const raiseError = (errorStack: string[]): any => {
	const { URL } = useConfigurationStore.getState();
	window.parent.postMessage(
		{ type: 'raise-error', errorStack },
		{ targetOrigin: URL },
	);
};

export { raiseError };

import { Fragment } from 'react';
import { Component } from 'editor/grapes-js/Component';
import ApkDeepLink from 'modules/attribute-settings/outcoming-interaction/interaction-types/ApkDeepLink';
import PromsApp from 'modules/attribute-settings/outcoming-interaction/interaction-types/PromsApp';
import RobotToRobot from 'modules/attribute-settings/outcoming-interaction/interaction-types/RobotToRobot';
import ScreenTransition from 'modules/attribute-settings/outcoming-interaction/interaction-types/ScreenTransition';
import SendSMS from 'modules/attribute-settings/outcoming-interaction/interaction-types/sending-sms/SendSMS';
import Telepresence from 'modules/attribute-settings/outcoming-interaction/interaction-types/Telepresence';
import {
	handleAddInteraction,
	handleRemoveInteraction,
	handleUpdateInteraction,
} from 'modules/attribute-settings/outcoming-interaction/outcomeInteraction.service';
import {
	filterInteractionTypes,
	interactionTypes,
} from 'shared/options/outcomingInteractions';
import { useGeneralSettingStore, useObjectSettingsStore } from 'store';

import ControlledInputGroup from 'components/input/ControlledInput';
import Sector from 'components/sector/Sector';
import SelectGroup from 'components/select/Select';

const OutcomingInteractionsTrait = () => {
	const [robotType] = useGeneralSettingStore((state) => [state.robotType]);

	const [object, properties] = useObjectSettingsStore((state) => [
		state.object,
		state.componentAttributes,
	]);

	Component.updateInstance(object!);

	const interactions = (properties.interactions as InteractionType[]) ?? [];

	const interactionInputs = interactions.map(
		(interaction: InteractionType, index) => (
			<Fragment key={index}>
				<SelectGroup
					label=""
					name="interaction-type"
					value={interaction['interaction-type']}
					keyName="interaction-type"
					options={filterInteractionTypes(interactions, interaction, robotType)}
					showClear={true}
					handleChange={(v) => handleUpdateInteraction(v, index)}
					handleClear={() => handleRemoveInteraction(index)}
				/>
				{interaction['interaction-type'] === 'screen-transition' && (
					<ScreenTransition interaction={interaction} index={index} />
				)}

				{interaction['interaction-type'] === 'apk-deeplink' && (
					<ApkDeepLink interaction={interaction} index={index} />
				)}

				{interaction['interaction-type'] === 'r2r' && (
					<RobotToRobot interaction={interaction} index={index} />
				)}

				{interaction['interaction-type'] === 'proms-app' && (
					<PromsApp
						interaction={interaction}
						index={index}
						robotType={robotType}
					/>
				)}

				{interaction['interaction-type'] === 'telepresence' && (
					<Telepresence interaction={interaction} index={index} />
				)}

				{interaction['interaction-type'] === 'send-sms' && (
					<SendSMS interaction={interaction} index={index} />
				)}

				<ControlledInputGroup
					id="interaction-id"
					name="interaction-id"
					label="Interaction ID"
					placeholder="Enter Interaction ID"
					maxLength={50}
					value={interaction.id ?? ''}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
						handleUpdateInteraction(
							{
								...interaction,
								id: e.target.value.toString(),
							},
							index,
						);
					}}
				/>

				{index < interactions.length - 1 && <hr />}
			</Fragment>
		),
	);

	return (
		<Sector title="Click interaction">
			<>
				{interactionInputs}

				{interactions.length < interactionTypes.length && (
					<button
						className="btn btn-sm text-primary"
						onClick={handleAddInteraction}
					>
						+ Add Interaction
					</button>
				)}
			</>
		</Sector>
	);
};

export default OutcomingInteractionsTrait;

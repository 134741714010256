const validVideoExtensions = [
	'mp4',
	'webm',
	'ogg',
	'm4a',
	'fmp4',
	'mkv',
	'flv',
	'fmpeg',
	'mpeg',
];

const validImageExtensions = [
	'avif',
	'jpg',
	'jpeg',
	'pjpeg',
	'png',
	'svg',
	'webp',
	'gif',
];

const validThumbnailVideoExtensions = ['mp4', 'webm'];

const objectUrlToFile = async (
	url: string,
	filename: string,
	filetype: string,
): Promise<File> => {
	return await fetch(url)
		.then(async (response) => await response.blob())
		.then((blob) => new File([blob], filename, { type: filetype }));
};

const objectUrlSize = async (url: string): Promise<number> => {
	return await fetch(url)
		.then(async (response) => await response.blob())
		.then((blob) => blob.size);
};

const getFileExtension = (file: File) => {
	return file.type ? file.type.split('/').at(1) : file.name.split('.').at(-1);
};

const getFileType = (file: File) => {
	const extension = getFileExtension(file);

	if (!extension) return undefined;

	if (validVideoExtensions.includes(extension.toLowerCase())) return 'video';

	if (validImageExtensions.includes(extension.toLowerCase())) return 'image';

	return undefined;
};

const validateFile = (file: File) => {
	const extension = getFileExtension(file);

	if (!extension) return false;

	return validVideoExtensions.concat(validImageExtensions).includes(extension);
};

const hasThumbnail = (file: File) => {
	const extension = getFileExtension(file);

	if (!extension) return false;

	return validThumbnailVideoExtensions.includes(extension);
};

export {
	getFileExtension,
	getFileType,
	hasThumbnail,
	objectUrlSize,
	objectUrlToFile,
	validateFile,
};

import React, { type FC } from 'react';

import styles from './tab.module.css';

type TabInfoType = Array<{
	id: string;
	title: string;
	active?: boolean;
	dynamicComponents?: FC[];
}>;

const Tab = ({ tabInfo }: { tabInfo: TabInfoType }) => {
	return (
		<div className={styles.tabContainer}>
			<ul
				className={`nav ${styles.tab} d-flex flex-nowrap justify-content-around align-items-center px-2`}
				role="tablist"
			>
				{tabInfo.map((tab) => (
					<li key={`${tab.id}-pane`} className="nav-item" role="presentation">
						<button
							className={`nav-link p-1 custom-nav-link ${
								tab.active ? 'active' : ''
							} ${styles.tabBtn}`}
							id={`${tab.id}-pane`}
							data-bs-toggle="tab"
							data-bs-target={`#${tab.id}`}
							type="button"
							role="tab"
							aria-controls={`${tab.id}`}
							aria-selected="true"
						>
							{tab.title}
						</button>
					</li>
				))}
			</ul>
			<div className={`tab-content ${styles.tabContentContainer}`}>
				{tabInfo.map((tab) => {
					return (
						<React.Fragment key={tab.id}>
							<div
								key={tab.id}
								id={tab.id}
								className={`tab-pane fade ${tab.active ? 'show active' : ''}`}
								role="tabpanel"
								aria-labelledby={`${tab.id}-pane`}
								tabIndex={0}
							>
								{tab.dynamicComponents?.map((SubComponent, index) => (
									<SubComponent key={index} />
								))}
							</div>
						</React.Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default Tab;
